















import Vue from 'vue';
import {mapState} from 'vuex';

export default Vue.extend({
  name: "SearchInput",
  data() {
    return {
      input: '',
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      virtualKeyboardValues: (state) => state.virtualKeyboardValues,
      // @ts-ignore
      virtualKeyboardRelatedId: (state) => state.virtualKeyboardRelatedId,
      // @ts-ignore
      showVirtualKeyboard: (state) => state.showVirtualKeyboard,
    }),
  },
  watch: {
    virtualKeyboardValues() {
      if (this.virtualKeyboardRelatedId === "search-input"){
        this.input = this.virtualKeyboardValues ?? '';
      }
    },
    input(){
      if (this.input !== this.virtualKeyboardValues){
        this.$store.dispatch('setKeyboardInput', { input: this.input })
      }
      this.$store.dispatch('setFilterInput', { input: this.input })
    },
  },
  methods: {
    clearInput() {
      this.input = '';
    },
    handleFocusedInput(idOfRelated){
      if (this.virtualKeyboardRelatedId !== idOfRelated && this.showVirtualKeyboard){
        this.$store.dispatch('setVirtualKeyboardRelatedId', { idOfRelated })
        this.$store.dispatch('setKeyboardInput', { input: this.input })
      }
    },
  },
})
