
import Vue from "vue";

export default Vue.extend({
  name: "Insurances",
  data() {
    return {
      insurances: [
        {
          logo: require("@/assets/images/insurances/aetna-logo.png"),
          name: "Aetna",
        },
        {
          logo: require("@/assets/images/insurances/unitedhealthcare.png"),
          name: "United Healthcare",
        },
        {
          logo: require("@/assets/images/insurances/blue-cross-blue-shield-logo.svg"),
          name: "BlueCross BlueShield",
        },
        {
          logo: require("@/assets/images/insurances/emblem-health-logo.png"),
          name: "Emblem Health",
        },
      ],
    };
  },
});
