
import Vue from 'vue';
import LinkedButton from '../components/ui-elements/LinkedButton.vue';
import SearchSlotsForm from '../components/basic/SearchSlotsForm.vue';
import {mapGetters} from 'vuex';
import ScheduledCards from '@/components/modules/ScheduledCards.vue';

export default Vue.extend({
  name: 'SearchSlots',
  components: {ScheduledCards, LinkedButton, SearchSlotsForm},
  data() {
    return {
      isSearching: false,
    }
  },
  created() {
    // Function to handle first open
  },
  computed: {
    ...mapGetters(['isSlotsLoaded']),
  },
  methods: {

  }
})
