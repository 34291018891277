













































































import Vue from 'vue';

export default Vue.extend({
  name: "ResetPassword",
  data() {
    return {
      valid: false,
      token: this.$route.query.token,
      email: '',
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      confirmPassword: '',
      validating: false,

      successState: false,
      successStateText: '',
      errorState: false,
      errorStateText: 'Something went wrong. Please try again.',
    }
  },
  created() {
    // Attach event listeners
    this.$eventBus.$on('emit-success', this.setSuccessState)
    this.$eventBus.$on('emit-error', this.setErrorState)
  },
  methods: {
    required: function(value) {
      if (value) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    min8: function(value) {
      if (value.length >= 8) {
        return true;
      } else {
        return 'Password should have more than 6 characters.';
      }
    },
    matchingPasswords: function() {
      if (this.password === this.confirmPassword) {
        return true;
      } else {
        return 'Passwords does not match.';
      }
    },
    async resetPassword() {
      const payload = {
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
      }

      const data = await this.$store.dispatch('resetPassword', payload);

      if (!!data && data.status) {
        this.$eventBus.$emit('emit-success', { state: true, text: data.message });
        this.forwardAfterResetPassword();
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: data.message });
      }
    },
    setErrorState({ state, text }) {
      this.errorState = state
      if (text || text === '') this.errorStateText = text
      else this.errorStateText = 'Something went wrong. Please try again.'
    },
    setSuccessState({ state, text }) {
      this.successState = state
      this.successStateText = text
    },
    forwardAfterResetPassword() {
      setTimeout(async () => {
        await this.$router.push({name: 'login'});
      }, 2000);
    }
  }
});

