
import Vue from 'vue';

export default Vue.extend({
  name: 'ScheduleSlot',
  props: {
    slotByTime: { type: Object, required: true },
  },
  data() {
    return {
      groupedDoctors: undefined,
      isVariantsOpen: false,
    }
  },
  computed: {
    variantsAsArray() {
      const variantsArray = [];

      this.slotByTime?.variants.map((data) => {

        const subArr = [];
        Object.entries(data).map(([key, value]) => {
          subArr.push(Object.assign({}, value));
        });

        variantsArray.push([
            ...subArr
        ]);
      });

      return variantsArray;
    },
    docsGropedByTimeAvailable() {
      const docsGroupByTime = [];

      if (this.variantsAsArray.length === 1) {
        const docs = [];
        const groupByVariant = [];

        this.variantsAsArray[0].map((version) => {
          const sameTimeIndex = groupByVariant.findIndex(({ time }) => time === version[0]);
          const docsWithOverlap = [];

          version[1].map((docInVersion) => {
            docsWithOverlap.push({
              ...docInVersion,
              isOverlap: !!version[3].includes(docInVersion.externalId),
            })
          });

          if (sameTimeIndex < 0) {
            groupByVariant.push({
              time: version[0],
              doctors: docsWithOverlap,
              timestamp: version[2],
            });

          } else {
            groupByVariant[sameTimeIndex].doctors.push(...version[1]);
          }
        });

        docsGroupByTime.push(groupByVariant);
      } else if (this.variantsAsArray.length > 1) {
        this.variantsAsArray.map((variant) => {
          const groupByVariant = [];
          variant.map((version) => {
            const sameTimeIndex = groupByVariant.findIndex(({ time }) => time === version[0]);
            const docsWithOverlap = [];

            version[1].map((docInVersion) => {
              docsWithOverlap.push({
                ...docInVersion,
                isOverlap: !!version[3].includes(docInVersion.externalId),
              })
            });

            if (sameTimeIndex < 0) {
              groupByVariant.push({
                time: version[0],
                doctors: docsWithOverlap,
                timestamp: version[2],
              });

            } else {
              const docsWithOverlap = [];

              version[1].map((docInVersion) => {
                docsWithOverlap.push({
                  ...docInVersion,
                  isOverlap: !!version[3].includes(docInVersion.externalId),
                })
              });

              groupByVariant[sameTimeIndex].doctors.push(...docsWithOverlap);
            }

          });

          docsGroupByTime.push(groupByVariant);
        });

      }

      return docsGroupByTime;
    },
    combainedDoctorVariants() {
      if (this.docsGropedByTimeAvailable.length > 0) {
        const returnArr = [];
        this.docsGropedByTimeAvailable.map((variantedGroup) => {
          const combinatedArr = this.doctorsCombinations(Object.assign([], variantedGroup));
          returnArr.push(...combinatedArr);
        });
        return returnArr;
      } else {
        return [];
      }
    },
    iconRotationStyle() {
      return this.isVariantsOpen ? { transform: 'rotate(-180deg)' } : { transform: 'rotate(-150deg)' }
    }
  },
  methods: {
    toggleCards() {
      this.isVariantsOpen = !this.isVariantsOpen;
    },
    doctorsCombinations(arr) {
      if (arr.length === 0) {
        return [];
      }

      if (arr.length === 1) {
        return arr[0].doctors.map(doc => ({ time: arr[0].time, doctors: [doc] }));
      }

      const combinations = [];
      const restCombinations = this.doctorsCombinations(Object.assign([], arr.slice(1))); // Recursive call without the first element
      for (const doc of arr[0].doctors) {
        for (const restCombination of restCombinations) {
          combinations.push({ time: arr[0].time, doctors: [doc, ...restCombination.doctors] });
        }
      }
      return combinations;
    }
  }
})
