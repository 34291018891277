var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"p-2 text-sm flex",class:{
    error: _vm.error,
    'text-center': _vm.small,
    'justify-center': _vm.small,
    'justify-between': !_vm.small && _vm.selected,
    'remove-border': _vm.noBorder
  },style:({ color: this.selected && this.small ? 'white' : 'black' }),attrs:{"color":_vm.bgColor,"disabled":_vm.disabled,"max-width":_vm.maxWidth,"ripple":!_vm.unripple},on:{"click":_vm.onCardClicked}},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.initials)?_c('div',{staticClass:"text-xs font-bold text-center d-inline pa-1 rounded",style:({
        backgroundColor: _vm.initialsBackgroundColor,
        color: _vm.$options.filters.foregroundColor(_vm.initialsBackgroundColor),
      })},[_vm._v(" "+_vm._s(_vm.initials)+" ")]):_vm._e(),(_vm.duration)?_c('span',{staticClass:"pa-1 text-xs font-weight-light"},[_vm._v(" "+_vm._s(this.durationFormat)+" ")]):_vm._e()]),(!_vm.small && _vm.selected)?_c('div',{staticClass:"action-icon"},[_c('v-btn',{attrs:{"disabled":"","icon":""}},[_c('v-icon',{style:({ fill: _vm.$vuetify.theme.themes.light.green.darken1 }),attrs:{"size":18}},[_vm._v(_vm._s(_vm.icons.selected))])],1)],1):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-indicator"},[_c('span',[_vm._v("No practitioners")]),_c('v-icon',{style:({ fill: _vm.$vuetify.theme.themes.light.red }),attrs:{"size":18}},[_vm._v(_vm._s(_vm.icons.error))])],1):_vm._e(),(_vm.remove && !_vm.error)?_c('div',{staticClass:"action-icon"},[_c('v-btn',{attrs:{"icon":""},on:{"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.onRemoveClicked.apply(null, arguments)}}},[_c('v-icon',{style:({ fill: _vm.$vuetify.theme.themes.light.gray.base }),attrs:{"size":18}},[_vm._v(_vm._s(_vm.icons.remove))])],1)],1):_vm._e(),(_vm.context && !_vm.error)?_c('div',{staticClass:"action-icon"},[_c('v-btn',{attrs:{"icon":""},on:{"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.onContextClicked.apply(null, arguments)}}},[_c('v-icon',{style:({ fill: _vm.$vuetify.theme.themes.light.gray.base }),attrs:{"size":18}},[_vm._v(_vm._s(_vm.icons.context))])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }