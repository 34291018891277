
import Vue from 'vue';

export default Vue.extend({
  name: 'LinkedButton',
  props: {
    link: { type: String, required: true },
    text: { type: String, required: true },
    classNames: { type: String },
    type: { type: String, default: 'primary' },
    mdiIcon: { type: String },
    iconSize: { type: Number, default: 24 },
    fontSize: { type: Number, default: 16 },
  },
  computed: {
    styles() {
      let bg: string;
      let border: string;
      let fontColor: string;

      switch (this.type) {
        case 'primary':
          bg = '#4E8EB1';
          border = '#4E8EB1';
          fontColor = '#ffffff';
          break;
        case 'secondary':
          bg = '#f5f5f5';
          border = '#111111';
          fontColor = '#111111';
          break;
        default:
          bg = '#EFEFEF';
          border = '#111111';
          fontColor = '#111111';
          break;
      }

      return {
        backgroundColor: bg,
        border: `1px solid ${border}`,
        color: fontColor,
        fontSize: this.fontSize,
        borderRadius: '10px',
        padding: '5px 10px'
      }
    },
  }
});
