import ApiService from '@/services/api.service';
import sum from 'hash-sum';
import dayjs from 'dayjs';
import Store from '@/store/index';

interface NoteRequestInterface {
  method: string;
  url: string;
  params: any;
}

const NoteService = {
  dashboard: undefined,
  roomIndex: undefined,
  treatmentIndex: undefined,
  noteIndex: undefined,
  beforeNotes: undefined,
  request: undefined,
  treatmentUUID: undefined,

  /**
   * Adds a new record to the Store, makes a request to add a record and, if an error occurs, returns the change
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async addNotes(request: NoteRequestInterface) {
    this.request = request;

    await this.setNotesBefore();
    const notesBefore = JSON.parse(JSON.stringify(this.beforeNotes));

    if (this.request.params.appointment_treatment) {
      const newNotes = this.addingToNotes();
      const payload = { notes: newNotes };
      Store.commit('setNotesForTreatmentDialog', payload);
    }

    try {
      const { data: { note_uuid, status } } = await ApiService.customRequest(request);

      if (this.request.params.appointment_treatment && status) {
        const newNotesWithCorrectUUID = this.updateNewAdded(note_uuid);
        const payloadWithUpdatedUUID = { notes: newNotesWithCorrectUUID };
        Store.commit('setNotesForTreatmentDialog', payloadWithUpdatedUUID);
      }
      return true;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('Error from request to add new note:', e);

      if (this.request.params.appointment_treatment) {
        const payloadOnFail = { notes: notesBefore };
        Store.commit('setNotesForTreatmentDialog', payloadOnFail);
      }
      return false;
    }
  },

  /**
   * Updates note in the Store, makes a request to update the note record, if an error occurs, returns the change
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async updateNotes(request: NoteRequestInterface) {
    this.request = request;

    await this.setNotesBefore();
    await this.searchNoteIndexDashboard();
    const notesBefore = JSON.parse(JSON.stringify(this.beforeNotes));

    if (this.request.params.appointment_treatment) {
      const newNotes = this.updateNoteInState();
      const payload = { notes: newNotes };
      Store.commit('setNotesForTreatmentDialog', payload);
    }

    try {
      await ApiService.customRequest(request);

      return true;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('Error from request to update existing note:', e);

      if (this.request.params.appointment_treatment) {
        const payloadOnFail = { notes: notesBefore };
        Store.commit('setNotesForTreatmentDialog', payloadOnFail);
      }
      return false;
    }
  },

  /**
   * Deletes note in the Store, makes a request to update the note record, if an error occurs, returns the change
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async deleteNotes(request: NoteRequestInterface) {

    this.dashboard = Store.state.auth.dashboard;
    this.request = request;

    await this.setNotesBefore();
    await this.searchNoteIndexDashboard();
    const notesBefore = JSON.parse(JSON.stringify(this.beforeNotes));

    if (this.request.params.appointment_treatment) {
      const newNotes = this.deleteNoteFromState();
      const payload = { notes: newNotes };
      Store.commit('setNotesForTreatmentDialog', payload);
    }

    try {
      await ApiService.customRequest(request);

      return true;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('Error from request to delete note:', e);
      if (this.request.params.appointment_treatment) {
        const payloadOnFail = { notes: notesBefore };
        Store.commit('setNotesForTreatmentDialog', payloadOnFail);
      }
      return false;
    }
  },

  /**
   * SEARCH and Set notes before editing state
   *
   * @async
   * @protected
   */
  async setNotesBefore() {
    if (!this.request.params.appointment_treatment) {
      this.beforeNotes = JSON.parse(JSON.stringify([]));
      return;
    }
    const notesBefore = Store.state.notesForTreatment;

    this.beforeNotes = JSON.parse(JSON.stringify(notesBefore)) ?? false;
  },

  /**
   * CREATE new object and prepare array to update state of notes
   *
   * @protected
   */
  addingToNotes() {
    let newNotes;
    const beforeNotes = this.beforeNotes;
    const user = Store.state.auth.user;

    const message = this.request.params.message;
    const treatmentId = this.request.params.treatment_id;
    const patientId = this.request.params.patient_id;
    const newNote = {
      message,
      user,
      patient_id: patientId,
      treatment_id: treatmentId,
      created_at: dayjs(),
      new: true,
      uuid: sum(Math.random()),
    };
    newNotes = [ newNote, ...beforeNotes ];

    return newNotes;
  },

  updateNewAdded(newNoteUUID: string) {
    let notes;
    const newNotes = this.addingToNotes();

    notes = newNotes.map((note) => {
      if (note.new) {
        note.new = false;
        note.uuid = newNoteUUID;
      }

      return note;
    });

    return notes;
  },

  /**
   * Update note object from state and prepare array to update state of notes
   *
   * @protected
   */
  updateNoteInState() {
    let newNotes;

    newNotes = this.beforeNotes;
    newNotes[Number(this.noteIndex)].message = this.request.params.message;

    return newNotes;
  },

  /**
   * DELETE note from state and prepare array to update state of notes
   *
   * @protected
   */
  deleteNoteFromState() {

    if (!this.request.params.appointment_treatment) {
      return;
    }

    let newNotes;
    const beforeNotes = this.beforeNotes;

    const noteUUID = this.request.params.uuid;
    newNotes = beforeNotes.filter(({uuid}) => uuid !== noteUUID);

    return newNotes;
  },

  /**
   * Mapping dashboard to find exact room, treatment and note, after saving it in object to use further
   *
   * @protected
   */
  searchNoteIndexDashboard() {

    if (!this.request.params.appointment_treatment) {
      return;
    }

    const uuid = this.request.params.uuid;
    this.beforeNotes.some((note, rKey) => {
      if (note.uuid === uuid) {
        this.noteIndex = rKey;
      }
    });


    if (this.noteIndex === undefined) {
      throw new Error('Couldn\'t find room, treatment or note index in dashboard state');
    }
  },
};

export default NoteService;
