
import Vue from "vue";
import ExtendedDoctorCard from "@/components/cards/ExtendedDoctorCard.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SelectTime",
  components: { ExtendedDoctorCard },
  props: {
    selectedDoctor: {
      type: Object,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slots: [],
      slotsKeys: [],
      firstDate: undefined,
      lastDate: undefined,
      selectedDoctorLocationId: undefined,
    };
  },
  created() {
    this.setSlots(this.selectedDoctor?.slots);

    this.setDates();

    const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
    if (hash) {
      this.scrollToDate(hash);
    } else {
      this.scrollToTop();
    }
  },
  watch: {
    selectedDoctor(val, oldVal) {
      this.setSlots(this.selectedDoctor?.slots);
    },
    slots: "setDates",
    selectedDoctorLocationId() {
      if (this.selectedDoctorLocationId !== this.selectedDoctor.locationId) {
        this.$emit("set-selected-location", this.selectedDoctorLocationId);

        const doc = this.sameSelectedDoctors.find(
          (doc) => doc.locationId === this.selectedDoctorLocationId
        );
        this.$emit("set-selected-doctor", doc);
      }
    },
    "$route.hash": function (newHash) {
      const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
      if (hash) {
        this.scrollToDate(hash);
      } else {
        this.scrollToTop();
      }
    },
  },
  computed: {
    ...mapGetters(["bookingLoading", "bookingTemplates", "bookingLocations"]),
    nextDates() {
      const currentNextDate = this.lastDate.object.clone();
      const start = currentNextDate.add(1, "day");
      const end = start.add(7, "day");

      return {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      };
    },
    prevDates() {
      const currentLastDate = this.firstDate.object.clone();
      const end = currentLastDate.subtract(1, "day");
      const start = end.subtract(7, "day");

      return {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      };
    },
    isPrevWeekDisabled() {
      const today = this.$dayjs().startOf("day");
      const prevStartDate = this.$dayjs(this.prevDates.start);
      return prevStartDate.isBefore(today);
    },

    sameSelectedDoctors() {
      const sameDocs = [];

      if (this.selectedDoctor) {
        const docExternalId = this.selectedDoctor.externalId;

        this.bookingTemplates
          .flatMap(({ doctors, id, location_id, type }) =>
            doctors.map((doc) => ({
              ...doc,
              templateId: id,
              templateType: type,
              locationId: location_id,
            }))
          )
          .forEach((doc) => {
            if (
              doc.data.externalId === docExternalId &&
              doc.templateType === this.selectedPatientType &&
              doc.locationId !== this.selectedDoctor.locationId
            ) {
              const template = this.bookingTemplates.find(
                ({ id }) => id === doc.templateId
              );
              const location = this.bookingLocations.find(
                ({ id }) => id === template.location_id
              );
              sameDocs.push({
                specialty: doc.data.specialty,
                uuid: doc.data.uuid,
                reviewWidgetClass: doc.data.review_widget_class,
                templateId: doc.templateId,
                externalId: doc.data.externalId,
                name: doc.data.name,
                shortBio: doc.data.shortBio,
                avatar: doc.data.avatar,
                slots: doc.slots,
                location: location.address,
                phoneNumber: location?.phone_numbers,
                locationId: location?.id ?? null,
                locationName:
                  location?.short_public_name ?? location?.name ?? null,
                locationPosition: location?.position ?? null,
              });
            }
          });

        return sameDocs;
      }
    },
    hasAnotherLocations() {
      return !!this.sameSelectedDoctors && this.sameSelectedDoctors.length > 0;
    },
    sameDoctorsList() {
      return this.sameSelectedDoctors.map((doctor) => {
        return {
          name: `${doctor.locationName} - ${doctor.location}`,
          locationId: doctor.locationId,
        };
      });
    },
  },
  methods: {
    scrollToDate(date) {
      this.$nextTick(() => {
        const dateContainer = this.$refs[`date-${date}`];
        if (dateContainer && dateContainer[0]) {
          dateContainer[0].scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    selectTime(date: String, time: String) {
      const dateTimeStr = `${date} ${time}`;
      const timeObj = this.$dayjs
        .tz(dateTimeStr, "YYYY-MM-DD h:mm A", "US/Eastern")
        .toISOString();

      this.$emit("set-selected-time", timeObj);
    },
    setSlots(slots) {
      this.slotsKeys = Object.keys(slots);

      const slotsArrKeys = this.slotsKeys;

      this.slots = slotsArrKeys.map((day) => {
        return {
          dateLabel: this.$dayjs(day).format("dddd, MMMM D"),
          date: this.$dayjs(day).format("YYYY-MM-DD"),
          times: slots[day],
          timesCount: slots[day].length,
        };
      });
    },
    nextWeekSlots() {
      this.requestSlots(this.nextDates.start, this.nextDates.end);
    },
    prevWeekSlots() {
      this.requestSlots(this.prevDates.start, this.prevDates.end);
    },
    async requestSlots(startDate, endDate) {
      const payload = {
        data: {
          doctor_external_id: this.selectedDoctor?.externalId,
          template_id: this.selectedDoctor?.templateId,
          startDate: startDate,
          endDate: endDate,
        },
      };

      const slots = await this.$store.dispatch(
        "LOAD_SLOTS_FOR_DOCTOR",
        payload
      );

      if (!!slots) {
        this.setSlots(slots);
      }
    },
    setDates() {
      let firstDate = null;
      let lastDate = null;

      if (this.slots.length > 0) {
        firstDate = this.$dayjs(this.slots[0].date);
        lastDate = this.$dayjs(this.slots[this.slots.length - 1].date);
      }

      this.firstDate = {
        label: firstDate ? firstDate.format("D MMMM") : null,
        object: firstDate ?? null,
      };

      this.lastDate = {
        label: lastDate ? lastDate.format("D MMMM") : null,
        object: lastDate ?? null,
      };
    },
  },
});
