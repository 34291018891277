
import Vue from 'vue';
import equal from 'fast-deep-equal';

export default Vue.extend({
  name: 'TherapyCard',
  props: {
    therapy: { type: Object, required: true },
  },
  data() {
    return {
      doctorsSelectedAll: false,
      selectedDoctors: [],
    }
  },
  watch: {
    doctorsSelectedAll() {
      if (this.doctorsSelectedAll) {
        this.selectedDoctors = this.allDoctorsUUID;
      } else if (!this.doctorsSelectedAll && equal(this.selectedDoctors, this.allDoctorsUUID) ) {
        this.selectedDoctors = [];
      }
    },
    selectedDoctors() {
      this.doctorsSelectedAll = equal(this.selectedDoctors, this.allDoctorsUUID);

      this.$emit('update-doctors-list', {
        therapyUUID: this.therapy.therapyUUID,
        doctorsExternalIDs: this.selectedDoctors
      });
    },
    therapy() {
      this.selectedDoctors = [];
      this.$emit('update-doctors-list', {
        therapyUUID: this.therapy.therapyUUID,
        doctorsExternalIDs: this.selectedDoctors
      });
    }
  },
  computed: {
    allDoctorsUUID() {
      return this.therapy.doctors.map(({externalId}) => externalId);
    }
  },
  methods: {

  }
})
