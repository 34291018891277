import { render, staticRenderFns } from "./AppointmentTreatmentSelector.vue?vue&type=template&id=90c47316&scoped=true&"
import script from "./AppointmentTreatmentSelector.vue?vue&type=script&lang=ts&"
export * from "./AppointmentTreatmentSelector.vue?vue&type=script&lang=ts&"
import style0 from "./AppointmentTreatmentSelector.vue?vue&type=style&index=0&id=90c47316&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90c47316",
  null
  
)

export default component.exports