const queues = {

}


function debounce(
    key,
    func,
    wait = 0,
    {
        leading = false,
        cancelObj = 'canceled'
    } = {}
) {
    queues[key] = {}; // timerId, latestResolve, shouldCancel, args

    return function ( ...args ) {
        if ( !queues[key][1] ) { // The first call since last invocation.

            return new Promise( ( resolve, reject ) => {
                queues[key][1] = resolve
                if ( leading ) {
                    invokeAtLeading.apply(this, [args, resolve, reject]);
                } else {
                    queues[key][0] = setTimeout( invokeAtTrailing.bind( this, args, resolve, reject ), wait )
                }
            })
        }

        queues[key][2] = true
        return new Promise( ( resolve, reject ) => {
            queues[key][1] = resolve
            queues[key][0] = setTimeout( invokeAtTrailing.bind( this, args, resolve, reject ), wait )
        })
    }



    function invokeAtLeading( args, resolve, reject ) {
        if ( queues[key][2] && resolve !== queues[key][1] ) {
            reject( cancelObj )
        } else {
        func.apply( this, args ).then( resolve ).catch( reject )

        queues[key][2] = false;
        }
    }

    function invokeAtTrailing( args, resolve, reject ) {
        if ( queues[key][2] && resolve !== queues[key][1] ) {
            reject( cancelObj )
        } else {
            func.apply( this, args ).then( resolve ).catch( reject )
            queues[key][2] = false
            clearTimeout( queues[key][0] )
            queues[key][0] = queues[key][1] = null
        }
    }
}

function reset(key)
{
    clearTimeout( queues[key][0] );
    queues[key][2] = true;
    queues[key][1] = null;
}

export default {debounce, reset};