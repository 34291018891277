

















































import Vue from 'vue';

export default Vue.extend({
  name: "ForgotPassword",
  data() {
    return {
      valid: false,
      email: '',
      emailRules: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      validating: false,
      successState: false,
      errorState: false,
      errorStateText: 'Something went wrong. Please try again.',
    }
  },
  created() {
    this.$eventBus.$on('emit-error', this.setErrorState)

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    async sendResetLinkToEmail() {
      const data = await this.$store.dispatch('forgotPassword', {email: this.email});

      if (!!data && data.status) {
        this.successState = true;
      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: data.message ?? this.errorStateText });
      }
    },

    setErrorState({ state, text }) {
      this.errorState = state
      if (text || text === '') this.errorStateText = text
      else this.errorStateText = 'Something went wrong. Please try again.'
    },
  }
});
