import Vue from 'vue';
import {Module} from 'vuex';

import {callbacks} from '../';
import router from '../../router';
import ApiService from '../../services/api.service';
import {AuthenticationError, UserService} from '@/services/auth.service';
import {StorageService} from '@/services/storage.service';

import type {RootState} from '..';
import dayjs from 'dayjs';
import debounce from '@/services/debounce.service.js';
import {datadogRum} from '@datadog/browser-rum';

export interface AuthState {
  authenticating: boolean;
  accessToken: string;
  authenticationErrorCode: number;
  authenticationError: string;
  office: string;
  user: any | null;
  dashboard: any | null;
  userPermissions: string[] | null;
  differenceInTime: boolean;
}

let debounced_dashboard = debounce.debounce('dashboard',  (uuid, user) => ApiService.customRequest({
  method: 'get',
  url: `dashboard/${uuid}`,
  params: { user: user },
}), 2000, {"leading": false, "cancelObj": new Error("Dashboard debounced")});


export const auth: Module<AuthState, RootState> = {
  namespaced: false,
  state: {
    authenticating: false,
    accessToken: StorageService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    office: '',
    user: null,
    dashboard: null,
    userPermissions: [],
    differenceInTime: false,
  },
  getters: {
    loggedIn: ({ accessToken, office }) => accessToken && office,
    userPermissions: ({ userPermissions }) => userPermissions,
    userRole: ({ user }) => user?.role,
    rooms: ({ dashboard }) => dashboard.rooms,
    userLocations: ({ user }) => user?.locations,
  },
  mutations: {
    authRequest(state) {
      state.authenticating = true;
      state.authenticationError = '';
      state.authenticationErrorCode = 0;
    },
    authSuccess(state, { token, user, permissions }: { token: string, user: any, permissions: any[] }) {
      state.accessToken = token;
      state.user = user;
      state.authenticating = false;
    },
    setPermissions(state, { permissions }: { permissions: any[] }) {
      const permissionObj = [];

      if (permissions.length > 0) {
        permissions.map( (perm) => permissionObj.push(perm.name));
      }

      state.userPermissions = permissionObj;
    },
    removePermissions(state) {
      state.userPermissions = [];
    },
    authError(state, { errorCode, errorMessage }) {
      state.authenticationErrorCode = errorCode;
      state.authenticationError = errorMessage;
      state.authenticating = false;
    },

    logoutSuccess(state) {
      state.accessToken = '';
      state.user = Object.assign({}, { uuid: null });
      state.office = '';
    },
    setOffice(state, { data }) {
      state.office = data;
      StorageService.saveOfficeId(data);
    },
    setDashboard(state, { data }) {
      const { message, office, rooms, status, waiting } = data;
      state.dashboard = {
        message,
        office,
        rooms: rooms.map((x: any) => ({
          ...x,
          appointments: x.appointments
            .map(callbacks.appointments.map.offsetStartTime(office.uuid)),
        })),
        status,
        waiting,
      };
    },
    setTreatmentAfterChange(state, { data }) {
      const { appointment } = data;
      state.dashboard.rooms.map((elem, key) => {
        if (!!appointment.room && elem.uuid === appointment.room.uuid) {
          const roomKey = key;
          elem.appointments.map((appoint, keyAppt) => {
            if (appoint.uuid === appointment.uuid) {
              state.dashboard.rooms[roomKey].appointments[keyAppt].treatments = appointment.treatments?.map(
                (y) => ({
                ...y,
                ...(y?.started_at
                  ? {
                    started_at: dayjs(y.started_at)
                      // .add(5, 'hour')
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'),
                  }
                  : {}
                  ),
                }),
              );
            }
          });
        }
      });
    },
    addAppointmentToRoom(state, data) {
      const {appointment, roomIndex} = data;
      state.dashboard.rooms.map((room, key) => {
        const sameAppt = room.appointments.find((appt) => appt.uuid === appointment.uuid);
        if (!!sameAppt) { state.dashboard.rooms[key].appointments.shift(); }
      });
      state.dashboard.rooms[roomIndex].appointments.push(appointment);
    },
    removeFromRoom(state, data) {
      const {appointmentUUID} = data;
      state.dashboard.rooms.map((room, key) => {
        const sameAppt = room.appointments.find((appt) => appt.uuid === appointmentUUID);
        if (!!sameAppt) {
          state.dashboard.rooms[key].appointments.shift();
        }
      });
    },
    setNotes(state, payload) {
      const { notes, uuid } = payload;
      const {roomIndex} = searchRoomIndexDashboard(state, uuid);

      state.dashboard
        .rooms[roomIndex]
        .appointments[0]
        .patient
        .notes = notes;
    },
    setTreatmentInDashboard(state, payload) {
      console.log('-1');
      debounce.reset('dashboard');

      console.log('-2');
      const { treatment } = payload;
      const {roomIndex, treatmentIndex} = searchTreatmentIndexDashboard(state, treatment.uuid);
      console.log('-3');

      state.dashboard
        .rooms[roomIndex]
        .appointments[0].status = treatment.status;

      console.log('-4');
      Vue.set(state.dashboard
        .rooms[roomIndex]
        .appointments[0]
        .treatments, treatmentIndex, treatment);
    },
    setUser(state, { data }) { state.user = data; },
    setDifferenceInTime(state, diffInSecs: number) {
      state.differenceInTime = diffInSecs > 10; // IF difference MORE than 10 secs
    },
  },
  actions: {
    async authenticate(
      { commit, dispatch },
      { email, password }: { email: string, password: string },
    ) {
      commit('authRequest');
      try {
        const { token, user, permissions } = await UserService.login(email, password);
        commit('authSuccess', { token, user });
        commit('setPermissions', { permissions });
        return true;
      } catch (e) {
        dispatch('onError', e);
        console.log('HERE R PROBLEM', e);
        return false;
      }
    },
    async forgotPassword({  dispatch }, payload: {email: string}) {
      try {
        return await UserService.forgotPassword(payload.email);
      } catch (e) {
        dispatch('onError', e);
        return false;
      }
    },
    async resetPassword({  dispatch }, payload) {
      try {
        return await UserService.resetPassword(payload);
      } catch (e) {
        return e;
      }
    },
    async authLogout({ commit }) {
      await UserService.logout();
      commit('logoutSuccess');
      commit('removePermissions');
      datadogRum.clearUser();
      router.push('/login');
    },
    async LOAD_DASHBOARD(
      { state: { office, user }, commit, dispatch },
      payload?,
    ) {
      try {
        const uuid = payload?.uuid ?? office;

        const { data } = await debounced_dashboard(uuid, user.uuid);
        commit('setDashboard', { data });

        if (!!data.time) {
          const timeInBackend = data.time;
          const currentTime = dayjs().unix();
          const diff = Math.abs(currentTime - timeInBackend);
          commit('setDifferenceInTime', diff);
        }

        return true;
      } catch (e) {
        dispatch('onError', e);
        return false;
      }
    },
    async LOAD_USER({ commit, dispatch }, { userId }) {
      try {
        const { data: { user: data, permissions } } =
          await ApiService.get<{ user: any, permissions: any }>(`users/${userId}`);
        commit('setUser', { data });
        commit('setPermissions', { permissions });

        const officeId = StorageService.getOfficeId();
        commit('setOffice', { data: officeId });
        logUserLoad(data, officeId);

        return true;
      } catch (e) {
        dispatch('onError', e);
        return false;
      }
    },
    ableUserTo({getters}, action: string) {
      return getters.userRole === 'Administrator' ? true : getters.userPermissions.includes(action);
    },
    onError({ commit }, e: unknown) {
      if (e instanceof AuthenticationError) {
        const { errorCode, message: errorMessage } = e;
        commit('authError', { errorCode, errorMessage });
      } else if (e instanceof Error){
        console.log(e.message);
      } else {
        console.log("Unexpected error");
      }
    },
  },
};

const logUserLoad = (user, officeId) => {
  const currentLocation = user.locations.find((location) => location.uuid === officeId);

  datadogRum.setUser({
    id: user.uuid,
    name: user.name,
    email: user.email,
    officeId: currentLocation?.uuid,
    officeName: currentLocation?.name,
  });
};


const searchTreatmentIndexDashboard = (state, uuid) => {

  let roomIndex;
  let treatmentIndex;

  state.dashboard.rooms.some((room, rKey) => {
    room.appointments[0]?.treatments.some((treatment, tKey) => {
      if (treatment.uuid === uuid) {
        roomIndex = rKey;
        treatmentIndex = tKey;
        return true;
      }
    });
  });

  return { roomIndex, treatmentIndex };
};


const searchRoomIndexDashboard = (state, uuid) => {

  let roomIndex;

  state.dashboard.rooms.some((room, rKey) => {
    if (room.appointments[0]?.uuid === uuid) {
      roomIndex = rKey;
      return true;
    }
  });

  return { roomIndex };
};
