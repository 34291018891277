
import Vue from 'vue';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
})

export default Vue.extend({
  name: 'DatePicker',
  props: {
    selectedDate: {
      type: String,
      required: true
    },
    format: {
      type: String,
      default: 'MM/DD/YYYY'
    }
  },
  watch: {
    datepickerVisible() {
      console.log('datepickerVisible', this.datepickerVisible);
    }
  },
  data() {
    return {
      datepickerVisible: false,
      dateWorkFormat: 'YYYY-MM-DD',
      weekDayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    };
  },
  computed: {
    formattedDate() {
      const weekDay = this.$dayjs(this.selectedDate).day();
      return `${this.$dayjs(this.selectedDate).format(this.format)} ${this.weekDayNames[weekDay]}`;
    },
  },
  methods: {
    showDatepicker() {
      this.datepickerVisible = true;
    },
    updateSelectedDate(newDate) {
      this.datepickerVisible = false;
      const dateToReturn = this.$dayjs(newDate).format(this.dateWorkFormat);
      this.$emit('update-date', dateToReturn);
    },
  }
});
