
import Vue from 'vue';
import {mapGetters} from 'vuex';
import equal from 'fast-deep-equal';
import DatePicker from '@/components/ui-elements/DatePicker.vue';
import dayjs from 'dayjs';
import TherapyCard from '@/components/cards/TherapyCard.vue';

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

export default Vue.extend({
  name: 'SearchSlotsForm',
  components: {TherapyCard, DatePicker},
  created() {
    const date = dayjs();
    this.startDate = date.format('YYYY-MM-DD');
    this.endDate = date.add(1, 'week').format('YYYY-MM-DD');
  },
  data() {
    return {
      location: undefined,
      template: undefined,
      days: [],
      selectedStartTime: '10:30',
      selectedEndTime: '19:30',
      daysSelectedAll: false,
      startDate: undefined,
      endDate: undefined,
      sendingTherapies: [],
    }
  },
  watch: {
    location: 'loadTemplates',
    template() {
      const t = {};

      this.therapies.forEach((therapy) => {
        t[therapy.therapyUUID] = [
            therapy.type,
            therapy.duration,
            []
        ];
      });

      this.sendingTherapies = t;
    },
    days() {
      this.daysSelectedAll = equal(this.days, this.weekDays);
    },
    daysSelectedAll() {
      if (this.daysSelectedAll) {
        this.days = this.weekDays;
      } else if (!this.daysSelectedAll && equal(this.days, this.weekDays)) {
        this.days = [];
      }
    },
    weekDays() {
      if (!!this.weekDays && this.weekDays.length > 0) {
        this.daysSelectedAll = true;
        this.days = [
          ...this.weekDays,
        ];
      } else {
        this.days = [];
      }
    }
  },
  computed: {
    ...mapGetters(['userLocations', 'templates']),
    weekDays() {
      const startDate = this.$dayjs(this.startDate);
      const endDate = this.$dayjs(this.endDate);
      let currentDate = startDate;
      const weekdays = new Set();

      while (currentDate.isSameOrBefore(endDate)) {
        const thisWeekDay = currentDate.format('ddd');

        if (currentDate.day() >= 0 && currentDate.day() <= 6) {
          weekdays.add(thisWeekDay);
        }
        currentDate = currentDate.add(1, 'day');
      }

      return Array.from(weekdays);
    },
    times() {
      const start = new Date();
      const end = new Date();
      start.setHours(5, 30, 0);
      end.setHours(19, 0, 0);
      let arr = [];

      while (start <= end) {
        start.setMinutes(start.getMinutes() + 30);
        arr.push({
          value: start.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false }),
          label: start.toLocaleString('en-US', {hour: '2-digit', minute: '2-digit'})
        });
      }

      return arr;
    },
    therapies() {
      if (!this.template) return [];
      return this.templates.find(({id}) => id === this.template).therapies ?? [];
    }
  },
  methods: {
    loadTemplates() {
      this.$store.dispatch('LOAD_LOCATION_TEMPLATES', { locationUUID: this.location });
      this.$store.commit('resetSlots');
      this.template = undefined;
    },
    async sendSearchQuery() {
      this.$emit('is-loading');
      const payload = {
        location: this.location,
        days: this.days,
        from: this.$dayjs(this.startDate).format('DD-MM-YYYY'),
        till: this.$dayjs(this.endDate).format('DD-MM-YYYY'),
        start: this.selectedStartTime,
        finish: this.selectedEndTime,
        therapies: this.sendingTherapies,
      }

      await this.$store.dispatch('SEARCH_SLOTS', payload);
      this.$emit('is-loading');
    },
    updateDoctorsList(data: { therapyUUID: string, doctorsExternalIDs: string[] }) {
      const {therapyUUID, doctorsExternalIDs} = data;

      this.sendingTherapies[therapyUUID][2] = doctorsExternalIDs;
    }
  }
});
