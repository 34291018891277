
import Vue from 'vue';
import {mapState} from 'vuex';
import dayjs from 'dayjs';
import ScheduleSlot from '@/components/cards/ScheduleSlot.vue';
import {RootState} from '@/store';

export default Vue.extend({
  name: 'ScheduledCards',
  components: {ScheduleSlot},
  data() {
    return {

    }
  },
  created: () => {},
  computed: {
    ...mapState({
      slots: (state: RootState) => state.scheduler.slots,
    }),
    groupByDate() {
      const slots = Object.assign([], this.slots);
      const initialObj = {};


      return slots.reduce((groups, slot) => {
        const dateObj = dayjs(slot.datetime, 'MM/DD/YYYY hh:mm A');
        const date = dateObj.format('YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = {
            dateFormatted: dateObj.format('dddd - MM/DD/YY'),
            slots: []
          };
        }

        groups[date].slots.push(Object.assign({}, slot));
        return groups;
      }, initialObj);
    },
  },
  methods: {}
})
