


























import AppointmentTreatmentSelector from '@/components/modules/AppointmentTreatmentSelector.vue';
import TreatmentDetailSelector from '@/components/modules/TreatmentDetailSelector.vue';

import Vue from 'vue';
import {mapState} from 'vuex';
import {mdiClose} from '@mdi/js';
import {boolean} from 'boolean';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';

export default Vue.extend({
  name: 'AppointmentDialog',
  components: {
    TreatmentNotesCard,
    AppointmentTreatmentSelector,
    TreatmentDetailSelector
  },
  data() {
    return {
      dialog: true,
      closeIcon: mdiClose,
      step: 0,
      interval: undefined,
      count: 0,
      allowedToClose: true,
    }
  },
  created() {
    if (!this.interval) this.interval = setInterval(() => {
      this.count++;
    }, 30)
  },
  watch: {
    count() {
      if (this.count > 25 && this.dialog) {
        clearInterval(this.interval);
      }
    },
    appointment() {
      this.count = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.count++;
      }, 30)
    }
  },
  computed: {
    ...mapState(['selectedPatient', 'appointment'])
  },
  methods: {
    closeModal() {
      if (this.allowedToClose) {
        this.$emit('close-dialog');
      }
    },
    setAllowedToClose(value: boolean) {
      this.allowedToClose = value;
    },
    onShowSettings() {
      this.$emit('on-show-settings');
    },
    closeAppointment() {
      if (this.count > 20 && this.allowedToClose) {
        this.$store.dispatch('resetAppointment')
        clearInterval(this.interval);
      }
    }
  }
})
