
import Vue from "vue";
import SelectAppointmentType from "@/components/screens/BookingWidget/SelectAppointmentType.vue";
import SelectDoctor from "@/components/screens/BookingWidget/SelectDoctor.vue";
import SelectTime from "@/components/screens/BookingWidget/SelectTime.vue";
import ConfirmTime from "@/components/screens/BookingWidget/ConfirmTime.vue";
import EnterDetails from "@/components/screens/BookingWidget/EnterDetails.vue";
import InsuranceDetails from "@/components/screens/BookingWidget/InsuranceDetails.vue";
import { BookingStorageService } from "@/services/BookingStorage.service";
import { mapGetters } from "vuex";
import GoogleMap from "@/components/modules/GoogleMap.vue";
import { datadogRum } from "@datadog/browser-rum";
import posthog from "posthog-js";
import { MESSAGE_TYPES } from "@/constants/massageTypes";
import { BOOKING_SERVICES_NAMES } from "@/constants/bookingWidgetScreens";

export default Vue.extend({
  name: "BookingWidget",
  components: {
    GoogleMap,
    InsuranceDetails,
    EnterDetails,
    ConfirmTime,
    SelectTime,
    SelectAppointmentType,
    SelectDoctor,
  },
  data() {
    return {
      currentScreen: "new",
      screens: {
        NEW: "new",
        APPT_DOCTOR: "appt-doctor",
        APPT_SLOT: "appt-slot",
        APPT_CONFIRM_TIME: "appt-confirm-time",
        APPT_DETAILS: "appt-details",
        APPT_INSURANCE: "appt-insurance",
      },
      viewNames: {
        new: "Select Appointment Type Page",
        "appt-doctor": "Select Doctor Page",
        "appt-slot": "Select Time Slot Page",
        "appt-confirm-time": "Confirm Time Page",
        "appt-details": "Enter Patient Details Page",
        "appt-insurance": "Enter Insurance Details Page",
      },
      patientTypes: [
        { name: "New Patient", value: "new" },
        { name: "Existing Patient", value: "existing" },
      ],
      apptImg: [
        {
          id: 1,
          name: "Chiropractic",
          img: require("@/assets/images/chiropractic.png"),
          paymentType: "Insurance",
          infoText:
            "Chiropractic care is a safe, effective and natural approach to managing pain and other health-related issues. We invite you for a complimentary consultation today.",
        },
        {
          id: 2,
          name: "Occupational Therapy",
          img: require("@/assets/images/occupational_therapy.png"),
          paymentType: "Insurance",
          infoText:
            "Occupational therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.",
        },
        {
          id: 3,
          name: "Physical Therapy",
          img: require("@/assets/images/phisical_therapy.png"),
          paymentType: "Insurance",
          infoText:
            "Physical therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.",
        },
        {
          id: 4,
          name: "Acupuncture",
          img: require("@/assets/images/Acupuncture.png"),
          paymentType: "Insurance",
          infoText:
            "Acupuncture is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.",
        },
        {
          id: 5,
          name: "Massage Therapy",
          img: require("@/assets/images/MassageTherapy.png"),
          paymentType: "Cash",
          infoText:
            "Massage therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.",
        },
      ],
      massageTypes: MESSAGE_TYPES,
      apptDefaultImg: require("@/assets/images/default-treatment.png"),
      selectedTime: BookingStorageService.getTime() ?? null,
      selectedDoctor: BookingStorageService.getDoctor() ?? null,
      selectedMassageType: BookingStorageService.getMassageType() ?? null,
      selectedAppointmentType:
        BookingStorageService.getAppointmentType() ?? null,
      selectedLocation: BookingStorageService.getLocation() ?? "all",
      selectedPatientType: BookingStorageService.getPatientType() ?? "new",
      patientDetails: BookingStorageService.getPatientDetails() ?? null,
      email: BookingStorageService.getEmail() ?? "",
      booking: BookingStorageService.getBooking() ?? null,
    };
  },
  beforeCreate() {
    this.$store.dispatch("LOAD_TEMPLATES");
  },
  created() {
    this.updateScreenFromParams();

    // Load the Elfsight widget script
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://static.elfsight.com/platform/platform.js";
    scriptTag.async = true;
    document.head.appendChild(scriptTag);

    datadogRum.startView("BookingWidget");
  },
  watch: {
    "$route.params.screen": "updateScreenFromParams",
    bookingTemplates: "applyPrefilter",
  },
  mounted() {
    posthog.init("phc_OZ9ubBd0hbQ0syKux3jjXvdRwmEVLxTk5N571zpO0sh", {
      api_host: "https://us.i.posthog.com",
      autocapture: true,
      capture_pageview: true,
      persistence: "memory",
    });

    posthog.register({
      widget_type: "booking_widget", // Custom property to identify the booking widget
    });

    posthog.capture("page_view", {
      widget_type: "booking_widget",
      view_name: this.viewNames[this.currentScreen] ?? "BookingWidget",
    });

    this.currentScreen =
      this.$route.params.screen.toLowerCase() ?? this.screens.NEW.toLowerCase();

    this.sendMessageToParent();

    if (
      this.currentScreen !== this.screens.NEW &&
      !this.selectedAppointmentType
    ) {
      this.navigateToStep(this.screens.NEW);
    }
  },
  updated() {
    posthog.capture("page_view", {
      widget_type: "booking_widget",
      view_name: this.viewNames[this.currentScreen] ?? "BookingWidget",
    });
  },
  computed: {
    ...mapGetters(["bookingTemplates", "bookingLoading", "bookingLocations"]),
    locations() {
      const mappedLocations = this.bookingLocations.map((location) => {
        return {
          id: String(location.id),
          name: location.short_public_name ?? location.name,
          address: location.address,
          phoneNumber: location.phone_numbers,
          value: String(location.id),
          position: location.position ?? null,
        };
      });

      return [
        {
          id: null,
          name: "All Locations",
          address: null,
          value: "all",
          position: null,
        },
        ...mappedLocations,
      ];
    },
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    isMapAvailable() {
      return [
        this.screens.NEW,
        this.screens.APPT_DOCTOR,
        this.screens.APPT_SLOT,
      ].includes(this.currentScreen);
    },
    appointmentTypes() {
      const apptTypes = new Map();

      this.bookingTemplates.forEach((template) => {
        if (apptTypes.has(template.public_name)) {
          const existing = apptTypes.get(template.public_name);
          existing.booked += template.booked;

          if (template.type === this.selectedPatientType) {
            existing.duration = `${template.duration} mins`;
          }

          if (template.public_name === BOOKING_SERVICES_NAMES.MASSAGE) {
            existing.duration = "60 - 90 mins";
          }
        } else {
          const img = this.apptImg.find(
            ({ name }) => name === template.public_name
          )?.img;
          const paymentType = this.apptImg.find(
            ({ name }) => name === template.public_name
          )?.paymentType;
          const infoText = this.apptImg.find(
            ({ name }) => name === template.public_name
          )?.infoText;
          let duration = `${template.duration} mins`;

          if (template.public_name === BOOKING_SERVICES_NAMES.MASSAGE) {
            duration = "60 - 90 mins";
          }

          apptTypes.set(template.public_name, {
            name: template.public_name,
            img: img ?? this.apptDefaultImg,
            value: template.public_name,
            paymentType: paymentType ?? "Insurance",
            duration: duration,
            infoText:
              infoText ??
              "Chiropractic care is a safe, effective and natural approach to managing pain and other health related issues. We invite you for a complimentary consultation today.",
            booked: template.booked,
          });
        }
      });

      return Array.from(apptTypes.values());
    },
    selectedApptTypesObj() {
      return this.appointmentTypes.find(
        (type) => type.value === this.selectedAppointmentType
      );
    },
    templatesForChoose() {
      return this.bookingTemplates.filter(
        (template) =>
          template.public_name === this.selectedAppointmentType &&
          template.type === this.selectedPatientType &&
          (this.selectedLocation !== "all"
            ? String(template.location_id) === String(this.selectedLocation)
            : true)
      );
    },
    doctors() {
      const locations = new Map();

      this.bookingLocations.forEach((location) => {
        locations.set(location.id, location);
      });

      let doctors = [];

      this.templatesForChoose.map((temp) => {
        const docs = temp.doctors.map((doc) => {
          const location = locations.get(temp.location_id);
          return {
            ...doc,
            address: location?.address ?? null,
            phoneNumber: location?.phone_numbers ?? null,
            locationName: location?.short_public_name ?? location?.name ?? null,
            locationId: location?.id ?? null,
            locationPosition: location?.position ?? null,
            templateId: temp.id,
          };
        });
        doctors = [...doctors, ...docs];
      });

      if (
        this.selectedApptTypesObj.name === BOOKING_SERVICES_NAMES.MASSAGE &&
        this.selectedMassageType
      ) {
        const selectedMassageDuration = this.massageTypes.find(
          ({ id }) => id === this.selectedMassageType
        )?.duration;
        const templateId = this.bookingTemplates.find(
          (template) =>
            template.public_name === BOOKING_SERVICES_NAMES.MASSAGE &&
            template.type === this.selectedPatientType &&
            template.duration === selectedMassageDuration
        )?.id;
        doctors = doctors.filter((doc) => doc.templateId === templateId);
      }

      return doctors.map((doc) => {
        return {
          name: doc.data.name,
          avatar: doc.data.avatar,
          reviewWidgetClass: doc.data.review_widget_class,
          shortBio: doc.data.short_bio,
          address: doc.address,
          phoneNumber: doc.phoneNumber,
          locationName: doc.locationName,
          locationId: doc.locationId,
          locationPosition: doc.locationPosition,
          uuid: doc.data.uuid,
          slots: doc.slots,
          specialty: doc.data.specialty,
          templateId: doc.templateId,
          externalId: doc.data.externalId,
        };
      });
    },
  },
  methods: {
    applyPrefilter() {
      // read query param and retrieve the selected location or select appointment type or doctor
      const { location, doctor, service } = this.$route.query;

      if (location) {
        this.setSelectedLocation(location);
      }

      if (doctor) {
        const doctors = new Map();
        this.bookingTemplates
          .flatMap(({ doctors, id }) =>
            doctors.map((doc) => ({
              ...doc,
              templateId: id,
            }))
          )
          .forEach((doc) => {
            doctors.set(
              this.createSlug(
                doc.data.externalId,
                doc.templateId,
                doc.data.name
              ),
              {
                ...doc.data,
                slots: doc.slots,
                templateId: doc.templateId,
              }
            );
          });

        const selectedDoctor = doctors.get(doctor);
        // console.log('Selected Doctor', selectedDoctor);
        const template = this.bookingTemplates.find(
          ({ id }) => id === selectedDoctor.templateId
        );
        const location = this.bookingLocations.find(
          ({ id }) => id === template.location_id
        );
        const selectedDoctorProps = {
          specialty: selectedDoctor.specialty,
          uuid: selectedDoctor.uuid,
          templateId: selectedDoctor.templateId,
          externalId: selectedDoctor.externalId,
          name: selectedDoctor.name,
          shortBio: selectedDoctor.shortBio,
          avatar: selectedDoctor.avatar,
          reviewWidgetClass: selectedDoctor.review_widget_class,
          slots: selectedDoctor.slots,
          location: location.address,
          phoneNumber: location?.phone_numbers,
          locationId: location?.id ?? null,
          locationName: location?.short_public_name ?? location?.name ?? null,
          locationPosition: location?.position ?? null,
        };

        console.log("Selected Doctor Props", selectedDoctorProps);
        this.selectedAppointmentType = template?.public_name;
        this.selectedPatientType = template?.type;
        this.setSelectedDoctor(selectedDoctorProps);

        this.navigateToStep(this.screens.APPT_SLOT);
      }

      if (service) {
        const publicName = this.apptImg.find(
          ({ id }) => id === Number(service)
        )?.name;
        publicName ? this.setSelectedAppointmentType(publicName) : null;
        this.navigateToStep(this.screens.APPT_DOCTOR);
      }
    },
    createSlug(
      externalId: number,
      templateId: number,
      doctorName: string
    ): string {
      // Remove symbols and replace spaces with underscores
      const slug = doctorName
        .replace(/[^\w\s]/gi, "") // Remove symbols
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .toLowerCase(); // Convert to lowercase

      return `${externalId}-${templateId}-${slug}`;
    },
    updateScreenFromParams() {
      const screen = this.$route.params.screen.replace(/-/g, "_").toUpperCase();
      if (screen && this.screens[screen]) {
        this.currentScreen = this.screens[screen];
      } else {
        this.currentScreen = this.screens.CHOOSE_APPT_TYPE;
      }
    },
    navigateToStep(screen, hash = null) {
      const route = {
        name: "booking-widget",
        params: { screen: screen },
        hash: hash ? `#${hash}` : "",
      };

      this.$router.push(route).then(() => {
        this.sendMessageToParent();
        this.sendCustomEvent("navigate_to", { page: screen, details: route });
      });
    },
    sendMessageToParent() {
      const message = {
        type: "URL_CHANGE",
        url: window.location.href,
      };
      window.parent?.postMessage(message, "*");
    },
    setPatientType: function (type) {
      this.selectedPatientType = type;
      BookingStorageService.setPatientType(type);
      this.sendCustomEvent("select_patient_type", { type });
    },
    setSelectedLocation: function (location) {
      this.selectedLocation = location;
      BookingStorageService.setLocation(location);
      this.sendCustomEvent("select_location", { location });
    },
    setSelectedAppointmentType: function (type) {
      this.selectedAppointmentType = type;
      BookingStorageService.setAppointmentType(type);
      this.sendCustomEvent("select_appointment_type", { type });
    },
    setSelectedMassageType: function (massageId) {
      this.selectedMassageType = massageId;
      BookingStorageService.setMassageType(massageId);
      console.log("Selected Massage Type:", massageId);
      this.sendCustomEvent("select_massage_type", { massageId });
    },
    setSelectedDoctor: function (doc) {
      this.selectedDoctor = doc;
      BookingStorageService.setDoctor(doc);
      this.sendCustomEvent("select_doctor", { doctor: doc });
    },
    setSelectedTime: function (time) {
      this.selectedTime = time;
      BookingStorageService.setTime(time);
      this.navigateToStep(this.screens.APPT_CONFIRM_TIME);
      this.sendCustomEvent("select_time", { time: time });
    },
    setEmail: async function (email: String) {
      this.email = email;
      BookingStorageService.setEmail(email);

      const templateId = this.selectedDoctor?.templateId;
      const doctorUUID = this.selectedDoctor?.uuid;

      const payload = {
        data: {
          template_id: templateId,
          doctor_uuid: doctorUUID,
          email: this.email,
          scheduled_at: this.selectedTime,
          massageType: null,
        },
      };

      if (this.selectedApptTypesObj?.name === BOOKING_SERVICES_NAMES.MASSAGE) {
        const massageType = this.massageTypes.find(
          ({ id }) => id === this.selectedMassageType
        );
        payload.data.massageType = massageType?.name;
      }

      const booking = await this.$store.dispatch(
        "SAVE_APPOINTMENT_DETAILS",
        payload
      );

      if (booking) {
        this.booking = booking;
        BookingStorageService.setBooking(booking);
        this.sendCustomEvent("confirm_time_and_email", {
          templateId: templateId,
          templateName: this.templatesForChoose.find(
            (template) => template.id === templateId
          )?.name,
          doctor: this.selectedDoctor,
          time: this.selectedTime,
        });
        this.navigateToStep(this.screens.APPT_DETAILS);
      } else {
        console.error("Error occurred while requesting to save details");
      }
    },
    bookAppointment: async function (patientDetails) {
      this.patientDetails = patientDetails;
      BookingStorageService.setPatientDetails(patientDetails);

      const payload = {
        data: {
          booking_id: this.booking.id,
          first_name: this.patientDetails.firstName,
          last_name: this.patientDetails.lastName,
          phone_number: this.patientDetails.phoneNumber,
          zipCode: this.patientDetails.zipCode,
          date_of_birth: this.patientDetails.dateOfBirth,
          gender: this.patientDetails.sex,
          comments: this.patientDetails.comments,
          insurance_status: this.patientDetails.insuranceStatus,
        },
      };

      const booking = await this.$store.dispatch("BOOK_APPOINTMENT", payload);

      if (booking) {
        this.booking = booking;
        BookingStorageService.setBooking(booking);

        this.sendCustomEvent("booking", {
          booking_id: this.booking.id,
          templateId: this.selectedApptTypesObj?.id,
          templateName: this.selectedApptTypesObj?.name,
          bookingDetails: payload.data,
        });
        this.selectedApptTypesObj.paymentType === "Insurance" &&
        this.patientDetails.insuranceStatus === "Have Insurance"
          ? this.navigateToStep(this.screens.APPT_INSURANCE)
          : this.forwardToThankYouPage();
      } else {
        console.error("Error occurred while booking appointment in DrChrono");
      }
    },
    checkBenefits: function (details) {
      const payload = {
        data: {
          insurance_details: details,
          booking_id: this.booking.id,
        },
      };

      const booking = this.$store.dispatch("SAVE_INSURANCE", payload);

      if (booking) {
        this.sendCustomEvent("insurance_details", payload);
        this.forwardToThankYouPage();
      } else {
        console.error("Error occurred while saving insurance details");
      }
    },
    forwardToThankYouPage() {
      const message = {
        type: "REDIRECT_TO_URL",
        url: "/thank-you-appointment",
      };
      posthog.capture("redirect_to", {
        widget_type: "booking_widget",
        view_name: "Thank You Page",
      });
      window.parent?.postMessage(message, "*");
    },
    sendCustomEvent(eventName: string, eventParams: object) {
      posthog.capture(eventName, {
        widget_type: "booking_widget",
        view_name: this.viewNames[this.currentScreen] ?? "BookingWidget",
      });

      if (window.gtag) {
        window.gtag("event", eventName, eventParams);
        datadogRum.addAction(eventName, eventParams);
      } else {
        console.error("GA4 is not initialized");
      }
    },
  },
});
