


















import Vue from 'vue';
import {mapState} from 'vuex';

declare interface BaseData {
  clock: number | undefined;
  userId: string | null;
  cssVariables: { [key: string]: string },
  logo: any;
  showAppBar: boolean;
}

export default Vue.extend({
  name: 'App',
  data(): BaseData {
    return {
      logo: require('@/assets/logoPrestige.svg'),
      clock: undefined,
      userId: null,
      cssVariables: { vh: '0' },
      showAppBar: true
    };
  },
  computed: {
    ...mapState({
      time: (state: any) => state.time,
      user: (state: any) => state.auth.user
    }),
    isCompactWindow() {
      return window.innerWidth <= 1162;
    }
  },
  created() {
    this.setClock();
    this.userId = this.$storage.getUserId();
    if (this.userId && !this.user) this.login();
    const styleHeading = 'font-size: 38px; color: red; text-shadow: 1px 1px 1px black;';
    const styleText =
        'font-size: 30px; color: red; text-shadow: 1px 1px 1px black; background: yellow;';
    /* eslint-disable-next-line */
    console.log(
        `%c⚠️ WARNING ⚠️\n` +
        `%cPlease do not copy and paste anything here; ` +
        `you might become a victim of a hacker attack. ` +
        `Learn more about Self-XSS: https://en.wikipedia.org/wiki/Self-XSS`,
        styleHeading,
        styleText,
    );

  },
  mounted() {
    this.setCssVariableVh()

    this.showAppBar = !this.$route.path.startsWith('/booking/');

    window.addEventListener('resize', () => this.setCssVariableVh)
  },
  beforeDestroy() {
    clearInterval(this.clock);
    window.removeEventListener('resize', () => this.setCssVariableVh)
  },
  methods: {
    async login(): Promise<void> {
      const payload = { userId: this.userId };
      await this.$store.dispatch('LOAD_USER', payload);
      const officeId = this.$storage.getOfficeId();
      this.$store.commit('setOffice', { data: officeId });
    },
    setClock(): void {
      this.clock = setInterval(() => {
        this.$store.dispatch('loadTime');
      }, 1000);
    },
    setCssVariableVh(): void {
      this.cssVariables.vh = `${window.innerHeight * 0.01}px`
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    }
  },
});
