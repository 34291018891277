
















































































































































import Vue from "vue";
import {mapState} from "vuex";
import PatientListItem from '@/components/basic/PatientListItem.vue';
import TextCard from '@/components/cards/TextCard.vue';

export default Vue.extend({
  name: "AppointmentsList",
  components: {PatientListItem,TextCard },
  data(){
    return {}
  },
  computed: {
  ...mapState([
      'appointmentHistory',
    ]),
  ...mapState({
    // @ts-ignore
    patient: (state) => state.appointment.patient
  })
  },
  methods: {
    formatTime(appointment) {
      const t = this.$dayjs(appointment.ended_at).unix() - this.$dayjs(appointment.starts_at).unix()

      if (!appointment.starts_at) {
        return 'Not started!'
      }

      if (!appointment.ended_at) {
        return 'Not completed!'
      }

      const isLongerThanOneHour = t >= 3600
      return `${
          this.$dayjs
              .utc(this.$dayjs.duration(t * 1000).asMilliseconds())
              .format(isLongerThanOneHour ? 'hh:mm' : 'mm:ss')
      }${
          isLongerThanOneHour ? 'm' : 's'
      }`
    },
  },
})
