<template>
  <div class="googleMaps_wrapper">
    <div class="map-container" ref="mapContainer"></div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "GoogleMap",
  props: {
    locations: {
      type: Array,
      required: true,
    },
    isMarkersClickable: {
      type: Boolean,
      default: true,
    },
    selectedLocationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      map: null,
      center: { lat: 40.731266, lng: -73.997915 },
      markers: [],
      infoWindows: [],
      selectedLocation: null,
    };
  },
  mounted() {
    if (this.locations && this.locations[0].id) {
      this.initMap();
    }
  },
  watch: {
    locations() {
      this.initMap();
    },
    selectedLocationId() {
      const isMobile = window.innerWidth <= 992;

      if (!!this.selectedLocationId && this.selectedLocationId !== "all") {
        const selectedLocation = this.locationsReal.find(
          (loc) => loc.id === this.selectedLocationId
        );
        const center = selectedLocation?.position ?? this.mapCenter;
        const zoom = isMobile ? 15 : 14;
        this.map.setZoom(zoom);
        this.map.panTo(center);

        const locationIndex = this.locationsReal.findIndex(
          (loc) => loc.id === this.selectedLocationId
        );

        this.closeAllInfoWindows();

        this.infoWindows[locationIndex].open(
          this.map,
          this.markers[locationIndex]
        );
      } else {
        this.map.panTo(this.mapCenter);
        this.closeAllInfoWindows();
        isMobile ? this.map.setZoom(13) : null;
      }
    },
  },
  computed: {
    mapCenter() {
      const positions = this.locationsReal.map((loc) => loc.position);
      const bound = new google.maps.LatLngBounds();
      positions.forEach((pos) => bound.extend(pos));
      return bound.getCenter();
    },
    locationsReal() {
      return this.locations.filter((loc) => !!loc.id && !!loc.position);
    },
  },
  methods: {
    async initMap() {
      let center = undefined;
      let zoom = 14;
      const isMobile = window.innerWidth <= 992;
      let showInfoWindow = false;

      if (this.locationsReal?.length === 1) {
        const pos = structuredClone(this.locationsReal[0].position);
        pos.lat = pos.lat + 0.001;
        center = pos;
        zoom = isMobile ? 16 : 12;
        showInfoWindow = true;
      } else {
        zoom = isMobile ? 13 : 14;
        center = this.center;
      }

      if (this.mapCenter) {
        center = this.mapCenter;
      }

      this.map = new google.maps.Map(this.$refs.mapContainer, {
        center: center,
        mapId: "9e032fad69f6bf80",
        zoom: zoom,
        disableDefaultUI: true,
        draggable: true,
        zIndex: 10,
      });

      this.locationsReal.forEach((location, index) => {
        if (location.position) {
          const marker = new google.maps.Marker({
            position: location.position,
            map: this.map,
            title: location.name,
            clickable: this.isMarkersClickable,
          });

          const infoWindow = new google.maps.InfoWindow(
            this.getInfoWindowOptions(location)
          );

          if (showInfoWindow) {
            infoWindow.open(this.map, marker);
          }

          marker.addListener("click", () => {
            this.closeAllInfoWindows();
            this.selectLocation(index);
            infoWindow.open(this.map, marker);
          });

          this.markers.push(marker);
          this.infoWindows.push(infoWindow);
        }
      });
    },
    getInfoWindowOptions(location) {
      const headerContent = document.createElement("h3");
      headerContent.innerText = location.name;
      headerContent.className = "infoWindowHeader";

      const content = `
        <div class="custom-label">
            ${
              location.phoneNumber
                ? '<b>Phone:</b>&nbsp;<a href="tel:' +
                  location.phoneNumber +
                  '">' +
                  location.phoneNumber +
                  "</a><br/>"
                : ""
            }
            ${
              location.address
                ? "<b>Address:</b>&nbsp;" + location.address + "<br/>"
                : ""
            }
        </div>`;

      return {
        headerContent,
        content,
        maxWidth: 200,
        disableAutoPan: true,
      };
    },
    selectLocation(index) {
      if (!this.isMarkersClickable) {
        return;
      }

      const location = this.locationsReal[index];
      this.$emit("set-selected-location", location?.id);
      this.selectedLocation = index;
    },
    closeAllInfoWindows() {
      this.infoWindows.forEach((infoWindow) => {
        infoWindow.close();
      });
    },
  },
});
</script>

<style>
.custom-label {
  padding: 0 5px 5px 0;
  border-radius: 3px;
  font-size: 12px;
}

.infoWindowHeader {
  font-weight: 600;
}
</style>

<style scoped>
.googleMaps_wrapper {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.location-info {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
</style>
