










































































































import { boolean } from 'boolean';
import Vue from 'vue';
import { mapState } from 'vuex';
import {mdiClose, mdiCog} from '@mdi/js';
import TextCard from '@/components/cards/TextCard.vue';

declare interface BaseData {
  icons: { [key: string]: string },
  selectedTime: number,
  selectedAdmin: number | string,
  selectedRoom: number | string,
  times: number[]
}

export default Vue.extend({
  name: 'TreatmentDetailSelector',
  components: { TextCard },
  data(): BaseData {
    return {
      icons: { settings: mdiCog, close: mdiClose },
      selectedTime: 0,
      selectedAdmin: '',
      selectedRoom: '',
      times: [10, 15, 30, 45, 60],
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      rooms: state => state.auth.dashboard.rooms,
      // @ts-ignore
      doctors: state => state.doctors,
      // @ts-ignore
      appointment: state => state.appointment,
      // @ts-ignore
      selectedTreatment: state => state.selectedTreatment
    }),
    roomOptions() {
      if (this.selectedTreatment?.rooms?.length) {
        return this.selectedTreatment.rooms;
      } else {
        return this.rooms;
      }
    },
    filteredDoctors() {
      return this.doctors
        .filter(({ is_enabled: x }) => boolean(x))
        .filter(d =>
          d.treatments.find(t => t.uuid === this.selectedTreatment.uuid)
        );
    },
    customTime(): boolean {
      return true;
    },
    addTreatmentButtonIsDisabled(): boolean {
      if (this.customTime) {
        return !this.selectedTime || !this.selectedAdmin;
      } else {
        return !this.selectedAdmin;
      }
    },
    params() {
      return {
        user: this.$store.state.auth.user.uuid,
        duration: this.selectedTime*60,
        treatment: this.selectedTreatment.uuid,
        doctor: this.selectedAdmin,
        room: this.selectedRoom,
      }
    }
  },
  mounted() {
    this.setInitialRoomSelection();
    this.selectedTime = this.selectedTreatment.duration || 0;
  },
  methods: {
    setInitialRoomSelection() {
      this.selectedRoom = this.appointment.room
        ? this.appointment.room.uuid
        : this.roomOptions[0].uuid;
    },
    selectTime(time: number): void {
      if (this.selectedTime === time) {
        this.selectedTime = 0;
      } else {
        this.selectedTime = time;
      }
    },
    handleSelectItem(property: any, id: number | string): void {
      if (property === 'selectedAdmin') {
        this.selectedAdmin = id;
      } else if (property === 'selectedRoom') {
        this.selectedRoom = id;
      }
    },
    async handleAddTreatment(): Promise<void> {
      const atPayload = {
        params: this.params,
        appointmentId: this.appointment.uuid
      };
      await this.$store.dispatch('ADD_APPOINTMENT_TREATMENT', atPayload);
      this.$emit('back');
    },
    handleClearTreatment(): void {
      this.$emit('back');
    },
    onShowSettings() {
      this.$emit('on-show-settings');
    }
  }
});
