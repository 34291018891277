import ApiService from '@/services/api.service';
import dayjs from 'dayjs';
import Store from '@/store/index';

interface TreatmentRequestInterface {
  method: string;
  url: string;
  params: any;
}

const TreatmentService = {
  dashboard: undefined,
  roomIndex: undefined,
  treatmentIndex: undefined,
  beforeTreatment: undefined,
  beforeAppointment: undefined,
  request: undefined,
  treatmentUUID: undefined,

  /**
   * Start counting treatment (status to processing) and request to back-end with update
   * and if falls request, set state with old version
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async play(request: TreatmentRequestInterface) {

    this.dashboard = Store.state.auth.dashboard;
    this.request = request;

    this.searchTreatmentIndexDashboard();
    this.setTreatmentBefore();
    const beforeTreatment = JSON.parse(JSON.stringify(this.beforeTreatment));

    const newTreatment = this.playTreatmentAction();
    Store.commit('setTreatmentInDashboard', { treatment: newTreatment });

    try {
      await ApiService.customRequest(request);
      return true;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('Error faced while play treatment:', e);
      Store.commit('setTreatmentInDashboard', { treatment: beforeTreatment });
      return false;
    }
  },

  /**
   * Pause counting treatment (status to paused) and request to back-end with update
   * and if falls request, set state with old version
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async pause(request: TreatmentRequestInterface) {

    this.dashboard = Store.state.auth.dashboard;
    this.request = request;

    await this.searchTreatmentIndexDashboard();
    await this.setTreatmentBefore();
    const beforeTreatment = JSON.parse(JSON.stringify(this.beforeTreatment));

    const newTreatment = this.pauseTreatmentAction();
    const payload = { treatment: newTreatment };
    Store.commit('setTreatmentInDashboard', payload);

    try {
      await ApiService.customRequest(request);
      return true;
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('Error faced while play treatment:', e);
      Store.commit('setTreatmentInDashboard', { treatment: beforeTreatment });
      return false;
    }
  },

  /**
   * Switch appointment between rooms or from waiting room
   * and if falls request, set state with old version
   *
   * @param {request object} request - Request object contains {url, method, params}.
   * @async
   * @public
   */
  async dragAndDrop(request: TreatmentRequestInterface) {

    this.dashboard = Store.state.auth.dashboard;
    this.request = request;

    await this.setAppointmentBefore();
    const beforeAppointment = JSON.parse(JSON.stringify(this.beforeAppointment));
    let apptToModal;

    if (['new'].includes(request.params.status)) { // when check in appointment into room by drop down
      const appointment = JSON.parse(JSON.stringify(this.beforeAppointment));
      appointment.status = 'paused';
      const roomIndex = request.params.localInfo.roomIndex;
      Store.commit('removeAppointmentFromQueue', { appointment });
      Store.commit('addAppointmentToRoom', { appointment, roomIndex },
      );

      if ([null, undefined, ''].includes(beforeAppointment.checkedin_at)) {
        apptToModal = beforeAppointment;
      }
    }
    if (['paused'].includes(request.params.status)) { // when switching between rooms
      const appointment = JSON.parse(JSON.stringify(this.beforeAppointment));
      appointment.status = 'paused';
      const roomIndex = request.params.localInfo.roomIndex;
      Store.commit('addAppointmentToRoom', { appointment, roomIndex },
      );
      apptToModal = beforeAppointment;
    } else if (['manual'].includes(request.params.status)) { // when make manual arrive
      const appointment = JSON.parse(JSON.stringify(this.beforeAppointment));
      appointment.status = 'waiting';
      appointment.manual = true;
      Store.commit('setAppointmentInList', { appointment });
      apptToModal = appointment;
    } else if (['waiting'].includes(request.params.status)) { // when return back to waiting from completed list
      const appointment = JSON.parse(JSON.stringify(this.beforeAppointment));
      const appointmentsInCompleted = Store.state.completedAppointments;
      appointment.status = 'waiting';
      Store.commit('setAppointmentInList', { appointment });
      Store.commit('setCompletedAppointments', { appointments: [
          ...appointmentsInCompleted.filter(({uuid}) => uuid !== appointment.uuid),
          appointment,
        ]});
      apptToModal = appointment;
    } else if (['upcoming'].includes(request.params.status)) { // when sending back to upcoming
      const appointment = JSON.parse(JSON.stringify(this.beforeAppointment));
      appointment.status = 'pending';
      appointment.manual = false;
      Store.commit('setAppointmentInList', { appointment });
      apptToModal = appointment;
    }  else if (['completed'].includes(request.params.status)) { // when check out
      Store.commit('removeFromRoom', { appointmentUUID: this.request.params.localInfo.appointmentId });
      Store.commit('toggleAppointment', { bool: false });
      apptToModal = null;
    }

    if (apptToModal) {
      await Store.dispatch('selectAppointment', {appointment: apptToModal}); // open Appointment Modal
    }

    try {
      await ApiService.customRequest(request);
      return true;
    } catch (e) {
      if (['new', 'manual', 'upcoming', 'completed'].includes(request.params.status)) {
        Store.commit('setAppointmentInList', {appointment: beforeAppointment});
      } else if (['completed', 'paused'].includes(request.params.status)) {
        Store.commit('addAppointmentToRoom',
          { appointment: beforeAppointment, roomIndex: this.roomIndex },
        );
      } else {
        Store.commit('setAppointmentInList', {appointment: beforeAppointment});
      }

      return false;
    }
  },

  /**
   * SEARCH and Set treatments before editing state
   *
   * @async
   * @protected
   */
  setTreatmentBefore() {
    const beforeTreatment = this.dashboard.rooms[this.roomIndex]
      .appointments[0]?.treatments[this.treatmentIndex];

    this.beforeTreatment = JSON.parse(JSON.stringify(beforeTreatment)) ?? false;
  },

  /**
   * SEARCH and Set treatments before editing state
   *
   * @async
   * @protected
   */
  async setAppointmentBefore() {
    const { localInfo: {appointmentId: uuid, status} } = this.request.params;
    let beforeAppointment = Store.state.appointments.find(({uuid: apptUUID}) => apptUUID === uuid);

    if (status === 'completed') {
      beforeAppointment = Store.state.completedAppointments.find(({uuid: apptUUID}) => apptUUID === uuid);
    }

    if (!beforeAppointment) {
      this.dashboard.rooms.map((room, key) => {
        if (room.appointments.length > 0 && room.appointments[0].uuid === uuid) {
          this.roomIndex = key;
        }
      });

      beforeAppointment = this.dashboard.rooms[this.roomIndex].appointments[0];
    }

    this.beforeAppointment = JSON.parse(JSON.stringify(beforeAppointment)) ?? false;
  },

  /**
   * Sets start time and changing status to 'processing' for appointment_treatment
   *
   * @protected
   */
  playTreatmentAction() {
    const treatment = JSON.parse(JSON.stringify(this.beforeTreatment));

    if (treatment.status === 'pending') {
      treatment.started_at = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
    }

    treatment.status = 'processing';
    treatment.resumed_at = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');

    return treatment;
  },

  /**
   * Prepare treatment object as paused
   *
   * @protected
   */
  pauseTreatmentAction() {
    const treatment = JSON.parse(JSON.stringify(this.beforeTreatment));

    const {time_elapsed: timeElapsed} = this.request.params;
    if (timeElapsed) {
      treatment.time_elapsed = Number(Math.floor(timeElapsed));
    }
    treatment.status = 'paused';
    treatment.resumed_at = null;

    return treatment;
  },

  /**
   * Mapping dashboard to find exact room, treatment, after saving it in object to use further
   *
   * @protected
   */
  searchTreatmentIndexDashboard() {

    const { treatment: uuid } = this.request.params;

    this.dashboard.rooms.some((roomObj, rKey) => {
      roomObj.appointments[0]?.treatments.some((treatment, tKey) => {
        if (treatment.uuid === uuid) {
          this.roomIndex = rKey;
          this.treatmentIndex = tKey;
          this.treatmentUUID = treatment.uuid;
          return true;
        }
      });
    });

    if (this.roomIndex === undefined || this.treatmentIndex === undefined) {
      throw new Error('Couldn\'t find room or treatment index in dashboard state');
    }
  },

};

export default TreatmentService;
