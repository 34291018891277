import ApiService from '@/services/api.service';
import pusher from '@/services/pusher.service';
import tc from '@replygirl/tc';
import {constantCase} from 'change-case';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import equal from 'fast-deep-equal';
import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import NoteService from '@/services/note.service';
import TreatmentService from '@/services/treatment.service';

import type {AuthState} from './modules/auth';
import {auth} from './modules/auth';
import {scheduler, SchedulerState} from './modules/scheduler';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {StorageService} from '@/services/storage.service';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import debounce from '@/services/debounce.service.js';
import {toUpper} from 'lodash';
import {booking} from '@/store/modules/booking';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.tz.setDefault('US/Eastern');

Vue.use(Vuex);

const debounced_appointments = debounce.debounce('appointments',  (office, user) => ApiService.get(`appointments/all/${office}`, {
  params: {
    end_date: dayjs().add(2, 'day').format('YYYY-MM-DD'),
    start_date: dayjs().format('YYYY-MM-DD'),
    user,
  },
}), 2000, {"leading": true, "cancelObj": new Error("Appointments debounced")});



declare interface ApiError {
  name: string;
  message: string;
  errorCode: number | null;
}

class ApiError extends Error {
  constructor(errorCode: number | null, message: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const throwApiError = (e: any) => {
  console.error(e);
  throw new ApiError(
    e?.response?.status ?? null,
    e?.response?.data?.message,
  );
};

export const callbacks = {
  appointments: {
    filter: {
      hasDoctor: (uuid?: string) => ({ doctor, doctors }) =>
        !uuid
        || doctor?.uuid === uuid
        || doctors?.map(({ doctor }) => doctor?.uuid).includes(uuid),
      isNotHidden: ({ doctors }) => {
        let display = false;

        if (doctors?.length === 0) { return true; }

        doctors?.map((t) => {
          // here we searching for elem not contains elem of array below
          if (!['No Show', 'Rescheduled', 'Cancelled'].includes(t.status)) {
            display = true;
          }
        });
        return display;
      },
      hasStartDate: ({ starts_at: x }) => !!x,
      isNotHere: ({ status: x }) =>
        !['processing', 'paused', 'waiting'].includes(x),
      isWaiting: ({ status: x }) => x === 'waiting',
      notCompleted: ({ status: x }) => x !== 'completed',
      startsToday: ({ starts_at: x }) => dayjs(x).isToday(),
      startsTomorrow: ({ starts_at: x }) => dayjs(x).isTomorrow(),
      filterBySearchElem: (searchFilter: string) => ({ patient, doctors, doctor }) => {
        const patientName = toUpper(patient?.name);
        const doctorName = toUpper(doctor?.name);
        let doctorsHasSearch = false;

        doctors?.map((doc) => {
          if (!doctorsHasSearch) {
            const docName = toUpper(doc?.doctor?.name);

            doctorsHasSearch = docName?.indexOf(searchFilter) >= 0;
          }
        });

        if (searchFilter !== '') {

          if (patient && patientName.indexOf(searchFilter) >= 0) {
            return true;
          }

          if (doctor && doctorName.indexOf(searchFilter) >= 0) {
            return true;
          }

          return !!(doctors && doctorsHasSearch);
        } else {
          return true;
        }
      },
    },
    map: {
      offsetStartTime: (officeUuid: string) => (x: any) => ({
        ...x,
        ...(
          (
            !!x.starts_at
          )
            ? {
              starts_at: dayjs(x.starts_at)
                // .tz('US/Eastern')
                // .add(4, 'hour') // TEMP
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
            }
            : {}
        ),
        treatments: x.treatments?.map((y) => ({
          ...y,
          ...(y?.started_at
              ? {
                started_at: dayjs(y.started_at)
                  // .tz('US/Eastern')
                  // .add(4, 'hour') // TEMP
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'),
                playedNotify: y.time_elapsed >= (y.duration - 180),
                playedWarning: y.time_elapsed >= y.duration,
              }
              : {}
          ),
        })) ?? [],
        doctors: x.doctors?.map((y) => ({
          ...y,
          ...(y?.scheduled_at
                  ? {
                    scheduled_at: dayjs(y.scheduled_at)
                        .utc()
                        .format('YYYY-MM-DD HH:mm:ss'),
                  }
                  : {}
          ),
        })) ?? [],
      }),
    },
    sort: {
      startsAt: {
        asc: ({ starts_at: a }, { starts_at: b }) => dayjs(a).diff(dayjs(b)),
        desc: ({ starts_at: a }, { starts_at: b }) => dayjs(b).diff(dayjs(a)),
      },
      endedAt: {
        asc: ({ ended_at: a }, { ended_at: b }) => dayjs(a).diff(dayjs(b)),
        desc: ({ ended_at: a }, { ended_at: b }) => dayjs(b).diff(dayjs(a)),
      },
      name: {
        alphabetic: ({ patient: { name: a }}, { patient: { name: b }}) => a.localeCompare(b),
      },
    },
  },
};

export interface State {
  administrators: any[];
  apiError: string;
  apiErrorCode: number;
  appointment: any | null;
  appointments: any[];
  openedTreatment: string | null;
  confirmation: {
    message: string
    uuid: string
    wasConfirmed: any | null,
  };
  confirmationMessage: string;
  confirmationStatus: boolean;
  confirmingItem: string;
  doctors: any[];
  newWindowOpen: boolean;
  errorState: boolean;
  history: any[];
  filter: string;
  lastAppointment: any | null;
  notes: any[];
  notesForTreatment: any[];
  flags: any[];
  tractionFlags: any[];
  profiles: any[];
  queueRefreshInterval: number | null;
  queueRefreshIntervalPeriod: number; // ms
  rooms: any[];
  selectedPatient: any | null;
  selectedTreatment: any | null;
  analyticInfo: any | null;
  analyticPatients: any | null;
  analyticDates: any | null;
  showAppointment: boolean;
  showConfirmation: boolean;
  stopLastAppointmentsOnMount: any[];
  time: Date | null;
  treatments: any[];
  virtualKeyboardValues: string;
  showVirtualKeyboard: boolean;
  virtualKeyboardRelatedId: string;
  users: any[];
  roles: any[];
  locationsAll: any[];
  practicesAll: any[];
  practiceCandidates: any[];
  doctorCandidates: any[];
  appointmentHistory: any[];
  searchFilterValue: string;
  isCheckedOutAppointmentsOpen: boolean;
  completedAppointments: any[];
  isLoadingCompletedAppointments: boolean;
  isCreateAppointmentModalOpen: boolean;
  patientCandidates: any[];
}

export interface RootState extends State {
  auth: AuthState;
  scheduler: SchedulerState;
}

const initState = (x: State) => x as RootState;

export default new Store<RootState>({
  state: initState({
    administrators: [],
    apiError: '',
    apiErrorCode: 0,
    appointment: null,
    appointments: [],
    openedTreatment: null,
    confirmation: {
      message: '',
      uuid: '',
      wasConfirmed: null,
    },
    confirmationMessage: '',
    confirmationStatus: false,
    confirmingItem: '',
    doctors: [],
    errorState: false,
    history: [],
    filter: '',
    lastAppointment: null,
    notes: [],
    notesForTreatment: [],
    flags: [],
    tractionFlags: [],
    profiles: [],
    queueRefreshInterval: null,
    queueRefreshIntervalPeriod: 5000, // ms
    rooms: [],
    selectedPatient: null,
    selectedTreatment: null,
    analyticInfo: null,
    analyticPatients: null,
    analyticDates: null,
    showAppointment: false,
    showConfirmation: false,
    stopLastAppointmentsOnMount: [],
    time: null,
    treatments: [],
    virtualKeyboardValues: '',
    showVirtualKeyboard: false,
    virtualKeyboardRelatedId: '',
    users: [],
    roles: [],
    locationsAll: [],
    practicesAll: [],
    practiceCandidates: [],
    doctorCandidates: [],
    newWindowOpen: false,
    appointmentHistory: [],
    searchFilterValue: '',
    isCheckedOutAppointmentsOpen: false,
    completedAppointments: [],
    isLoadingCompletedAppointments: false,
    isCreateAppointmentModalOpen: false,
    patientCandidates: [],
  }),
  getters: {
    appointments: ({ appointments, filter: uuid, searchFilterValue }) => appointments
      .filter(callbacks.appointments.filter.hasDoctor(uuid))
      .filter(callbacks.appointments.filter.isNotHidden)
      .filter(callbacks.appointments.filter.filterBySearchElem(searchFilterValue))
      .sort(callbacks.appointments.sort.startsAt.asc),
    appointmentsScheduled: (_, { appointments }) => appointments
      .filter(callbacks.appointments.filter.hasStartDate)
      .filter(callbacks.appointments.filter.notCompleted)
      .sort(callbacks.appointments.sort.name.alphabetic),
    appointmentsForManualCheck: (_, { appointments }) => appointments
      .filter(callbacks.appointments.filter.hasStartDate)
      .filter(callbacks.appointments.filter.notCompleted)
      .filter(callbacks.appointments.filter.startsToday),
    queue: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.isWaiting),
    queueUpcoming: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.startsToday)
      .filter(callbacks.appointments.filter.isNotHere)
      .sort(callbacks.appointments.sort.startsAt.asc),
    queueTomorrow: (_, { appointmentsScheduled }) => appointmentsScheduled
      .filter(callbacks.appointments.filter.startsTomorrow)
      .filter(callbacks.appointments.filter.isNotHere),
    users: (_, { users }) => users,
    checkedOutModalState: (state) => state.isCheckedOutAppointmentsOpen,
    completedAppointmentsSorted: (state) => {
      return state.completedAppointments.length > 0
        ? state.completedAppointments.sort(callbacks.appointments.sort.endedAt.desc) : [];
    },
    analyticPatientsList: (state) => {
      return state.analyticPatients?.length > 0
        ? state.analyticPatients.sort(callbacks.appointments.sort.startsAt.desc) : [];
    },
    loadingCompletedAppointments: (state) => state.isLoadingCompletedAppointments,
    notesForCurrentOpenTreatment: (state) => state.notesForTreatment,
    currentOpenFlags: (state) => state.flags,
    currentOpenTractionFlags: (state) => state.tractionFlags,
    currentOpenProfiles: (state) => state.profiles,
    createAppointmentModal: (state) => state.isCreateAppointmentModalOpen,
    patientCandidates: (state) => state.patientCandidates,
  },
  mutations: {
    apiError(
      state,
      { errorCode, errorMessage }: { errorCode: number, errorMessage: string },
    ) {
      state.auth.authenticating = false;
      state.apiErrorCode = errorCode;
      state.apiError = errorMessage;
    },
    toggleTreatment(state, { openedTreatmentUUID }: { openedTreatmentUUID: string | null}) {
      state.openedTreatment = openedTreatmentUUID;
    },
    setUsers( state, { users }: { users: any[] }) {
      state.users = users;
    },
    addUsersList( state, { user }: { user: any } ) {
      state.users = [
        user, ...state.users,
      ];
    },
    setSearchFilterValue(state, { value }: { value: string}) {
      state.searchFilterValue = toUpper(value);
    },
    updateUsersList( state, { user }: { user: any } ) {
      const users = [];
      state.users.map((eachUser) => {
        eachUser.uuid !== user.uuid ? users.push(eachUser) : users.push(user);
      });
      state.users = users;
    },
    removeUserFromUsersList( state, { userUUID }: { userUUID: string } ) {
      state.users = state.users.filter( (user) => user.uuid !== userUUID);
    },
    setRoles( state, { roles }: { roles: any[] }) {
      state.roles = roles;
    },
    addLocationToList(state, { location }: { location: any }) {
      state.locationsAll = [
        location, ...state.locationsAll,
      ];
    },
    setLocationsAll( state, { locationsAll }: { locationsAll: any[] }) {
      state.locationsAll = locationsAll;
    },
    setPracticesAll( state, { practicesAll }: { practicesAll: any[] }) {
      state.practicesAll = practicesAll;
    },
    setPracticeCandidates( state, { practiceCandidates }: { practiceCandidates: any[] }) {
      state.practiceCandidates = practiceCandidates;
    },
    setPatientCandidates( state, { patientCandidates }: { patientCandidates: any[] }) {
      state.patientCandidates = patientCandidates;
    },
    setDoctorCandidates( state, { doctorCandidates }: { doctorCandidates: any[] }) {
      state.doctorCandidates = doctorCandidates;
    },
    updateLocationsList( state, { location }: { location: any } ) {
      const locations = [];
      state.locationsAll.map((eachLocation) => {
        eachLocation.uuid !== location.uuid ? locations.push(eachLocation) : locations.push(location);
      });
      state.locationsAll = locations;
    },
    removeLocationFromList( state, { locationUUID }: { locationUUID: string } ) {
      state.locationsAll = state.locationsAll.filter( (location) => location.uuid !== locationUUID);
    },
    updatePracticesList( state, { practice }: { practice: any } ) {
      const practices = [];
      state.practicesAll.map((eachPractice) => {
        eachPractice.uuid !== practice.uuid ? practices.push(eachPractice) : practices.push(practice);
      });
      state.practicesAll = practices;
    },
    addPracticeToList(state, { practice }: { practice: any }) {
      state.practicesAll = [
        practice, ...state.practicesAll,
      ];
    },
    removePracticeFromList( state, { practiceUUID }: { practiceUUID: string } ) {
      state.practicesAll = state.practicesAll.filter( (practice) => practice.uuid !== practiceUUID);
    },
    setInputFromKeyboard(state, { input }: { input: string }) {
      state.virtualKeyboardValues = input;
    },
    setAdministrators(state, { admins }: { admins: any[] }) {
      state.administrators = admins;
    },
    setAppointment(state, { appointment }: { appointment: any }) {
      state.appointment = appointment;
    },
    setAppointmentHistory(state, { appointmentHistory }: { appointmentHistory: any }) {
      state.appointmentHistory = appointmentHistory;
    },
    setAppointments(state, { appointments }: { appointments: any[] }) {
      state.appointments = appointments;
    },
    setAppointmentInList(state, { appointment }: { appointment }) {
      const keyOfThisAppointment = state.appointments.findIndex((appt) => appt.uuid === appointment.uuid);
      let appointments = state.appointments;

      if (keyOfThisAppointment !== undefined) {
        appointments[keyOfThisAppointment] = appointment;
      } else {
        appointments = [
          appointment,
          ...state.appointments,
        ];
      }

      state.appointments = [
        ...appointments,
      ];
    },
    /*
    Remove appointment from list for queue when appointment checked in
     */
    removeAppointmentFromQueue(state, { appointment }: { appointment }) {
      const appointments = state.appointments;
      state.appointments = appointments.filter((appt) => appt.uuid !== appointment.uuid);
    },
    setConfirmation(state, { message, uuid, wasConfirmed }) {
      state.confirmation = Object.assign({}, state.confirmation, {
        message,
        uuid,
        wasConfirmed,
      });
    },
    setConfirmationWasConfirmed(state, wasConfirmed: boolean) {
      state.confirmation = Object.assign({}, state.confirmation, {
        wasConfirmed,
      });
    },
    setDoctors(state, { doctors }: { doctors: any[] }) {
      state.doctors = doctors;
    },
    setDoctorInList(state, { doctor }: { doctor }) {
      const keyOfThisDoctor = state.doctors.findIndex((doc) => doc.uuid === doctor.uuid);
      let doctors = state.doctors;

      if (keyOfThisDoctor !== undefined) {
        doctors[keyOfThisDoctor] = doctor;
      } else {
        doctors = [
          ...state.doctors,
          doctor,
        ];
      }

      state.doctors = [
        ...doctors,
      ];
    },
    removeDoctorFromList(state, { doctorUUID }: { doctorUUID }) {
      state.doctors = [
        ...state.doctors.filter((doc) => doc.uuid !== doctorUUID),
      ];
    },
    setErrorState(state, { error }: { error: boolean }) {
      state.errorState = error;
    },
    setFilter(state, { filter }: { filter: string }) {
      state.filter = filter;
    },
    setHistory(state, { history }: { history: any[] }) {
      state.history = history;
    },
    setLastAppointment(state, { lastAppointment }: { lastAppointment: any }) {
      state.lastAppointment = lastAppointment;
    },
    setNotesAll(state, { notes }: { notes: any[] }) {
      state.notes = notes;
    },
    setNotesForTreatmentDialog(state, { notes }: { notes: any[] }) {
      state.notesForTreatment = notes;
    },
    setFlagsForDisplay(state, { flags }: { flags: any[] }) {
      state.flags = flags;
    },
    setTractionFlagsForDisplay(state, { tractionFlags }: { tractionFlags: any[] }) {
      state.tractionFlags = tractionFlags;
    },
    setProfilesForDisplay(state, { profiles }: { profiles: any[] }) {
      state.profiles = profiles;
    },
    setQueueRefreshInterval(state, x: number | null) {
      state.queueRefreshInterval = x;
    },
    setRooms(state, { rooms }: { rooms: any[] }) {
      state.rooms = rooms;
    },
    setRoom(state, { room }: { room }) {
      const keyOfThisRoom = state.rooms.findIndex((r) => r.uuid === room.uuid);
      let rooms = state.rooms;

      if (keyOfThisRoom !== undefined) {
        rooms[keyOfThisRoom] = room;

        Vue.set(state.rooms, keyOfThisRoom, room);
      } else {
        rooms = [
          ...state.rooms,
          room,
        ];
      }

      state.rooms = [
        ...rooms,
      ];
    },
    removeRoom(state, { roomUUID }: { roomUUID }) {
      state.rooms = [
        ...state.rooms.filter((room) => room.uuid === roomUUID),
      ];
    },
    setPatient(state, { patient }: { patient: any }) {
      state.selectedPatient = patient;
    },
    setTime(state, { time }: { time: Date }) {
      state.time = time;
    },
    setTreatment(state, { treatment }: { treatment: any }) {
      state.selectedTreatment = treatment;
    },
    setTreatments(state, { treatments }: { treatments: any[] }) {
      state.treatments = treatments;
    },
    setTreatmentInList(state, { room }: { room }) {
      const keyOfThisTreatment = state.treatments.findIndex((r) => r.uuid === room.uuid);
      let treatments = state.treatments;

      if (keyOfThisTreatment !== undefined) {
        treatments[keyOfThisTreatment] = room;
      } else {
        treatments = [
          ...state.treatments,
          room,
        ];
      }

      state.treatments = [
        ...treatments,
      ];
    },
    removeTreatmentInList(state, { treatmentsUUID }: { treatmentsUUID }) {
      state.treatments = [
        ...state.treatments.filter((treatment) => treatment.uuid === treatmentsUUID),
      ];
    },
    setAnalyticInfo(state, { data }: { data: any[] }) {
      state.analyticInfo = data;
    },
    setAnalyticPatients(state, { appointments }: { appointments: any[] }) {
      state.analyticPatients = appointments;
    },
    setAnalyticDates(state, { data }: { data: any[] }) {
      state.analyticDates = data;
    },
    toggleAppointment(state, { bool }: { bool: boolean }) {
      state.showAppointment = bool;
    },
    toggleKeyboard(state, { bool }: {bool: boolean }) {
      state.showVirtualKeyboard = bool;
    },
    setVirtualKeyboardRelatedId(state, { idOfRelated }: {idOfRelated: string}) {
      state.virtualKeyboardRelatedId = idOfRelated;
      state.virtualKeyboardValues = ''; // clear input if user click to another input
    },
    setNewWindowOpen(state, { bool }: {bool: boolean}) {
      state.newWindowOpen = bool;

    },
    toggleConfirmationStatus(state, { bool }: { bool: boolean }) {
      state.confirmationStatus = bool;
    },
    toggleCheckedOutAppointmentsOpen(state, { bool }: {bool: boolean}) {
      state.isCheckedOutAppointmentsOpen = bool;
    },
    setCompletedAppointments(state, { appointments }: { appointments: any[] }) {
      state.completedAppointments = appointments;
    },
    setLoadingCompletedAppointments(state, { bool }: { bool: boolean }) {
      state.isLoadingCompletedAppointments = bool;
    },
    setIsCreateAppointmentModalOpen(state, { bool }: { bool: boolean }) {
      state.isCreateAppointmentModalOpen = bool;
    },
    updateAppointmentTreatments(state, payload: any) {
      const { room, appointment, treatments } = payload;
      // tslint:disable-next-line:max-line-length
      state.auth.dashboard.rooms.find((r) => r.uuid === room).appointments.find((a) => a.uuid === appointment).treatments = [...treatments];
    },
    updateHistory(state, { appointments }: { appointments: any[] }) {
      state.history = [
        ...state.history.filter((x) =>
          !appointments.find((y) =>
            (x.uuid === y.uuid) && !equal(x, y),
          ),
        ),
        ...appointments.filter((x) =>
          !state.history.find((y) =>
            (x.uuid === y.uuid) && equal(x, y),
          ),
        ),
      ];
    },
    updateStopLastAppointmentsOnMount(state, { uuid }: { uuid: string }) {
      console.log('updating stop last appointments on mount', uuid);
      state.stopLastAppointmentsOnMount.push(uuid);
    },
  },
  actions: {
    CLEAR_HISTORY({ commit }) { commit('setHistory', { history: [] }); },
    async TOGGLE_CHECKED_OUT_APPOINTMENTS_MODAL({ commit }) {
      const currentModalState = this.state.isCheckedOutAppointmentsOpen;
      commit('toggleCheckedOutAppointmentsOpen', { bool: !currentModalState });
    },
    TOGGLE_CREATE_APPOINTMENT_MODAL({ commit }) {
      const currentModalState = this.state.isCreateAppointmentModalOpen;
      commit('setIsCreateAppointmentModalOpen', { bool: !currentModalState });
    },
    async GET_COMPLETED_APPOINTMENTS({ commit }) {
      const officeUUID = this.state.auth.office;
      commit('setLoadingCompletedAppointments', { bool: true });
      try {
        const { data: { appointments } } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${officeUUID}/all/completed`,
        });

        commit('setCompletedAppointments', { appointments });
        commit('setLoadingCompletedAppointments', { bool: false });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_LOCATIONS({ commit }) {
      try {
        const { data: { locations, practices } } = await ApiService.customRequest({
          method: 'get',
          url: `locations/all`,
        });
        commit('setLocationsAll', { locationsAll: locations });
        commit('setPracticesAll', { practicesAll: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_LOCATION({ commit, dispatch }, payload) {
      try {
        const { locationUUID, changed, data } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/update`,
          data,
        });
        if (changed === 'office') {
          dispatch('GET_ALL_LOCATIONS');
        } else if (changed === 'info') {
          commit('updateLocationsList', { location });
        }
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_ACTIVITY_UPDATE({ commit, dispatch }, payload) {
      try {
        const { locationUUID, action } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/${action}`,
        });
        commit('updateLocationsList', { location });
        dispatch('LOAD_USER', { userId: StorageService.getUserId() });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { location } } = await ApiService.customRequest({
          method: 'post',
          url: `locations/add`,
          data,
        });
        commit('addLocationToList', { location });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOCATION_DELETE({ commit }, payload) {
      try {
        const { locationUUID } = payload;
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `locations/${locationUUID}/delete`,
        });
        commit('removeLocationFromList', { locationUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_PRACTICES({ commit }) {
      try {
        const { data: { practices }} = await ApiService.customRequest({
          method: 'get',
          url: `practices/all`,
        });
        commit('setPracticesAll', { practicesAll: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_PRACTICES_CANDIDATES({ commit }) {
      try {
        const { data: { practices }} = await ApiService.customRequest({
          method: 'get',
          url: `practices/candidates`,
        });
        commit('setPracticeCandidates', { practiceCandidates: practices });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_PATIENT_CANDIDATES({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { patients }} = await ApiService.customRequest({
          method: 'post',
          url: `patients/candidates`,
          data,
        });
        commit('setPatientCandidates', { patientCandidates: patients });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_APPOINTMENT_MANUALLY({ commit, state }, payload) {
      try {
        const { data } = payload;
        const officeUUID = state.auth.office;
        const response = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${officeUUID}/createManually`,
          data,
        });
        commit('setPatientCandidates', { patientCandidates: [] });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_ALL_DOCTORS_CANDIDATES({ commit, state }) {
      try {
        const officeUUID = state.auth.office;
        const { data: { doctors }} = await ApiService.customRequest({
          method: 'get',
          url: `doctors/${officeUUID}/candidates`,
        });
        commit('setDoctorCandidates', { doctorCandidates: doctors });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_PRACTICE({ commit }, payload) {
      try {
        const { practiceUUID, data } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/update`,
          data,
        });

        commit('updatePracticesList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_ACTIVITY_UPDATE({ commit }, payload) {
      try {
        const { practiceUUID, action } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/${action}`,
        });
        commit('updatePracticesList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { practice } } = await ApiService.customRequest({
          method: 'post',
          url: `practices/add`,
          data,
        });
        commit('addPracticeToList', { practice });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async PRACTICE_DELETE({ commit }, payload) {
      try {
        const { practiceUUID } = payload;
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `practices/${practiceUUID}/delete`,
        });
        commit('removePracticeFromList', { practiceUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async ALL_USERS({ commit }) {
      try {
        const { data: { users } } = await ApiService.customRequest({
          method: 'get',
          url: `users/all`,
        });
        commit('setUsers', { users });
        return users;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_CREATE({ commit }, payload) {
      try {
        const { data } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/add`,
          data,
        });
        commit('addUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_USER({ commit }, payload) {
      try {
        const { userUUID, data } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/update`,
          data,
        });
        commit('updateUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_PASSWORD_CHECK(_, payload: any) {
      try {
        const { data } = payload;
        const { data: { status }} = await ApiService.customRequest({
          method: 'post',
          url: `users/check-password`,
          data,
        });
        return status;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_PASSWORD_CHANGE(_, payload: any) {
      try {
        const { userUUID, data } = payload;
        const { data: info } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/password`,
          data,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_ACTIVITY_UPDATE({ commit }, payload) {
      try {
        const { userUUID, action } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/${action}`,
        });
        commit('updateUsersList', { user });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async USER_DELETE({ commit }, payload) {
      try {
        const { userUUID } = payload;
        const { data: { user } } = await ApiService.customRequest({
          method: 'post',
          url: `users/${userUUID}/delete`,
        });
        commit('removeUserFromUsersList', { userUUID });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_NOTES({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `notes/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setNotesAll', { notes: data.notes });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_NOTES_BY_TREATMENT({ commit }, payload) {
      const { params } = payload;
      try {
        const {
          data: { notes, medFlags: flags, tractionFlags: tractionFlags, profiles },
        } = await ApiService.customRequest({
          method: 'post',
          url: `notes/patient-treatment-notes`,
          params,
        });
        commit('setNotesForTreatmentDialog', { notes });
        commit('setFlagsForDisplay', { flags });
        commit('setTractionFlagsForDisplay', { tractionFlags });
        commit('setProfilesForDisplay', { profiles });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_PATIENT_NOTES({ state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `patients/${state.appointment.patient.uuid}`,
        });
        return data;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_PATIENT_APPOINTMENTS({ commit, state }) {
      try {
        const officeUUID = state.auth.office;
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `patients/${state.appointment.patient.uuid}/appointments/${officeUUID}`,
        });
        commit('setAppointmentHistory', {appointmentHistory: data.patient.appointments});
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_TREATMENTS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `treatments/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setTreatments', { treatments: data.treatments });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_DOCTORS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `doctors/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setDoctors', { doctors: data.doctor });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_ANALYTIC_INFO({ commit, state }, payload: any) {
      const { params } = payload;
      try {

        const {data} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/appointmentsAverageByPeriod`,
          params: {
            ...params,
            office: state.auth.office,
          },
        });
        commit('setAnalyticInfo', { data });
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_ANALYTIC_PATIENTS({ commit, state }, payload: any) {
      const { params } = payload;
      try {

        const { data: { appointments }} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/appointmentPatientList`,
          params: {
            ...params,
            office: state.auth.office,
          },
        });
        commit(
          'setAnalyticPatients',
          {
            appointments: appointments.map(callbacks.appointments.map.offsetStartTime(state.auth.office))
          },
        );
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async GET_APPT_START_END_DATES({ commit, state }, payload: any) {
      const { params } = payload;
      try {

        const {data} = await ApiService.customRequest({
          method: 'get',
          url: `analytics/getFirstAndLastApptDates`,
          params: {
            office: state.auth.office,
          },
        });
        commit('setAnalyticDates', { data });
        return true;

      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    async LOAD_ROOMS({ commit, state }) {
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `rooms/${state.auth.office}`,
          params: { user: state.auth.user.uuid },
        });
        commit('setRooms', { rooms: data.rooms });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_APPOINTMENT(
      {
        commit,
        state: {
          appointment: selectedAppointment,
          appointments,
          auth: {
            office,
            user: { uuid: user },
          },
        },
      },
      { appointmentId }: any,
    ) {

      return tc(async () => {
        const { data: { appointment, lastAppointment } } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentId}`,
          params: { user },
        });
        const match = (doesMatch = true) =>
          (x: any) => doesMatch === (x?.uuid === appointment.uuid);

        if (match()(selectedAppointment)) {
          commit('setAppointment', {
            appointment:
              callbacks.appointments.map.offsetStartTime(office)(appointment),
          });
          commit('setLastAppointment', { lastAppointment });
          commit('setFlagsForDisplay', { flags: appointment.patient.medFlags });
          commit('setTractionFlagsForDisplay', { tractionFlags: appointment.patient.tractionFlags });
          commit('setProfilesForDisplay', { profiles: appointment.profiles });
        }

        if (!equal(appointment, appointments.find(match()) ?? {})) {
          commit('setAppointments', ({
            appointments: [
              ...appointments.filter(match(false)),
              callbacks.appointments.map.offsetStartTime(office)(appointment),
            ],
          }));
        }

        return true;
      }, throwApiError);
    },
    async LOAD_SELECTED_APPOINTMENT(
      {
        commit,
        state: {
          auth: {
            office,
            user: { uuid: user },
          },
        },
      },
      { appointmentId }: any,
    ) {

      try {
        const { data: { appointment, lastAppointment } } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentId}`,
          params: { user },
        });

        if (appointment) {
          commit('setAppointment', {
            appointment:
              callbacks.appointments.map.offsetStartTime(office)(appointment),
          });
          commit('setLastAppointment', { lastAppointment });
          commit('setFlagsForDisplay', { flags: appointment.patient.medFlags });
          commit('setTractionFlagsForDisplay', { tractionFlags: appointment.patient.tractionFlags });
          commit('setProfilesForDisplay', { profiles: appointment.profiles });
        }
      } catch (err) {
        throwApiError(err);
        return false;
      }

      return true;
    },
    async ADD_APPOINTMENT_TREATMENT(
      {
        commit,
        state: {
          appointment: selectedAppointment,
          appointments,
          auth: {
            office,
            user: { uuid: user },
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/add`,
          params,
        });
        const match = (doesMatch = true) =>
          (x: any) => doesMatch === (x?.uuid === appointment.uuid);
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        if (!equal(appointment, appointments.find(match()) ?? {})) {
          commit('setAppointments', ({
            appointments: [
              ...appointments.filter(match(false)),
              callbacks.appointments.map.offsetStartTime(office)(appointment),
            ],
          }));
        }
        commit('setTreatmentAfterChange', { data: { appointment } });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_APPT_TREATMENT_ORDER(_, payload: any) {
      const { params, appointmentId } = payload;

      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateOrder`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_APPOINTMENT_TREATMENT(
      {
        commit,
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { params, appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/delete`,
          params,
        });
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async LOAD_LAST_APPOINTMENT_TREATMENTS({commit, state: { auth: { office }}}, payload: any) {
      const { appointmentId, params } = payload;
      try {
        const {data: { appointment, lastAppointment }} = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/load/previous-appointment`,
          params,
        });
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        commit('setLastAppointment', { lastAppointment });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_APPOINTMENT_ALL_TREATMENTS(
      {
        commit,
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { appointmentId } = payload;
      try {
        const { data: { appointment }} = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentId}/treatments/delete/all`,
        });
        commit('setAppointment', {
          appointment:
            callbacks.appointments.map.offsetStartTime(office)(appointment),
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_APPOINTMENT_STATUS(_, payload: any) {
      const { params, appointmentId } = payload;
      try {
        await TreatmentService.dragAndDrop({
          method: 'post',
          url: `appointments/${appointmentId}/status`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_PLAY_TREATMENT(_, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const data = await TreatmentService.play({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/play`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_PAUSE_TREATMENT(_, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const data = await TreatmentService.pause({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/pause`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_COMPLETE_TREATMENT({ commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/complete`,
          params,
        });
        commit('setTreatmentAfterChange', { data });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_RESET_TREATMENT({ commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/reset`,
          params,
        });
        commit('setTreatmentAfterChange', { data });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async HANDLE_UPDATE_ALLOCATED_TIME_TREATMENT({ commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateAllocatedTime`,
          params,
        });
        commit('setTreatmentAfterChange', { data });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_OF_APPOINTMENT_TREATMENT( { commit }, payload: any) {
      const { params, appointmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `appointments/${appointmentId}/treatments/updateDoctor`,
          params,
        });
        commit('setTreatmentAfterChange', { data });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_TREATMENT_NAME({ dispatch }, payload: any) {
      const { params, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/update`,
          params,
        });
          dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async TOGGLE_TREATMENT({ dispatch }, payload: any) {
      const { status, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/${status}`,
        });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_TREATMENT({ dispatch }, payload: any) {
      const { params, treatmentId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/${treatmentId}/delete`,
          params,
        });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_SETTINGS({ dispatch, commit }, payload: any) {
      const { params, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/update`,
          data: params,
        });
        commit('setDoctorInList', { doctor: data.doctor });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_BOOKING_AVAILABILITY({ dispatch, commit }, payload: any) {
      const { params, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/booking/${doctorId}/update-availability`,
          data: params,
        });
        commit('setDoctorInList', { doctor: data.doctor });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_STATUS({ dispatch, commit }, payload: any) {
      const { status, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/${status}`,
        });
        commit('setDoctorInList', { doctor: data.doctor });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_DOCTOR_TREATMENT({ dispatch, commit }, payload: any) {
      const { status, doctorId, params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/treatments/${status}`,
          params,
        });
        commit('setDoctorInList', { doctor: data.doctor });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_DOCTOR({ dispatch, commit }, payload: any) {
      const { params, doctorId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/${doctorId}/delete`,
          params,
        });
        commit('removeDoctorFromList', { doctorUUID: doctorId });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_SETTINGS({ commit }, payload: any) {
      const { params, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/update`,
          params,
        });
        commit('setRoom', {room: data.room} );
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOMS_ORDER(
      {
        state: {
          auth: {
            office,
          },
        },
      },
      payload: any,
    ) {
      const { params } = payload;

      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${office}/updateOrder`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_STATUS({ commit }, payload: any) {
      const { status, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/${status}`,
        });
        commit('setRoom', {room: data.room} );
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_ROOM_TREATMENT({ commit }, payload: any) {
      const { status, roomId, params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/treatments/${status}`,
          params,
        });
        commit('setRoom', {room: data.room} );
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_ROOM({ commit }, payload: any) {
      const { params, roomId } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/${roomId}/delete`,
          params,
        });
        commit('removeRoom', {roomUUID: roomId});
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_DOCTOR({ dispatch, commit }, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `doctors/add`,
          params,
        });
        commit('setDoctorInList', { doctor: data.doctor });
        dispatch('TRIGGER_CLIENT_RELOAD', ['doctors']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_ROOM(_, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `rooms/add`,
          params,
        });
        console.log({ params, data });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_TREATMENT({ dispatch }, payload: any) {
      const { params } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `treatments/add`,
          params,
        });
        console.log({ params, data });
        dispatch('TRIGGER_CLIENT_RELOAD', ['treatments']);
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async CREATE_NOTE(_, { params }: any) {
      try {
        return await NoteService.addNotes({
          method: 'post',
          url: `notes/add`,
          params,
        });
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async UPDATE_NOTE(_, { params }: any) {
      try {
        const data = await NoteService.updateNotes({
          method: 'post',
          url: `notes/edit`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async DELETE_NOTE(_, { params }: any) {
      try {
        const data = await NoteService.deleteNotes({
          method: 'post',
          url: `notes/delete`,
          params,
        });
        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async GET_APPOINTMENT_TREATMENTS(_, payload: any) {
      const { params, appointmentUuid } = payload;
      try {
        const { data } = await ApiService.customRequest({
          method: 'get',
          url: `appointments/${appointmentUuid}/treatments/getAll`,
          params,
        });
        return data;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },
    async selectAppointment({ commit }, { appointment }: { appointment: any }) {
      commit('setAppointment', { appointment });
      commit('toggleAppointment', { bool: true });
    },
    async resetAppointment({ commit }) {
      commit('setAppointment', { appointment: null });
      commit('toggleAppointment', { bool: false });
      commit('setFlagsForDisplay', { flags: [] });
      commit('setTractionFlagsForDisplay', { tractionFlags: [] });
      commit('setProfilesForDisplay', { profiles: [] });
    },
    async toggleKeyboard({ commit, state }) {
      commit('toggleKeyboard', { bool: !state.showVirtualKeyboard});

      // clearing virtual
      if (!state.showVirtualKeyboard) {
        commit('setInputFromKeyboard', ({
          input: '',
        }));
      }
    },
    async setKeyboardInput({ commit }, { input }: { input: string }) {
      commit('setInputFromKeyboard', ({
        input,
      }));
    },
    async setFilterInput({ commit }, { input }: { input: string }) {
      commit('setSearchFilterValue', {value: input});
    },
    async setVirtualKeyboardRelatedId({ commit }, { idOfRelated }: { idOfRelated: string }) {
      commit('setVirtualKeyboardRelatedId', ({
        idOfRelated,
      }));
    },
    async loadTime({ commit }) {
      commit('setTime', { time: dayjs().toDate() });
    },
    async selectTreatment({ commit }, { treatment }: { treatment: any }) {
      commit('setTreatment', { treatment });
    },

    // Appointments & queue
    async LOAD_APPOINTMENTS({
      commit,
      state: {
        auth: {
          office,
          user: { uuid: user },
        },
      },
    }) {
      return await tc(async () => {

        try {
          const {
            data: {
              appointments,
            },
          } = await debounced_appointments(office, user);

          commit('setAppointments', ({
            appointments: appointments.map(
                callbacks.appointments.map.offsetStartTime(office),
            ),
          }));
        } catch (e) {
          if (e instanceof Error) {
            console.log(e.message);
          }
        }
      });
    },

    /*resetDashboardDebounser()
    {
      debounced_appointments.reject();
    },*/

    // Confirmation dialogs
    ASK_FOR_CONFIRMATION({ commit }, { message, uuid }) {
      commit('setConfirmation', { message, uuid, wasConfirmed: null });
    },
    SET_CONFIRMATION_WAS_CONFIRMED({ commit }, wasConfirmed: boolean) {
      commit('setConfirmationWasConfirmed', wasConfirmed);
    },
    RESET_CONFIRMATION({ commit }) {
      commit('setConfirmation', { message: '', uuid: '', wasConfirmed: null });
    },
    async REGISTER_OPEN_NEW_WINDOW() {
      try {
        const rand = () => Math.random().toString(36).substr(2);
        const token = () => rand() + rand() + rand();

        window.name = token();
        const windowName = window.name;
        const params = { windowName };

        const { data } = await ApiService.customRequest({
          method: 'post',
          url: `window/new`,
          params,
        });

        return true;
      } catch (err) {
        throwApiError(err);
        return false;
      }
    },

    // Pusher
    async SUBSCRIBE_TO_CLIENT_RELOAD({ dispatch, commit }, { uuid }) {
      interface ReloadEventData { resources: string[]; }
      interface AppointmentEventData { appointment: { uuid: string }; }

      const userUuid = StorageService.getUserId();

      const onAppointmentChanged =
        ({ appointment: { uuid: appointmentId }}: AppointmentEventData) =>
          dispatch(`LOAD_APPOINTMENT`, { appointmentId });

      const onApptQueueUpdate = () => dispatch(`LOAD_APPOINTMENTS`);

      const userOpenNewTab = ({ windowName }) => {
        if (windowName !== window.name) {
          window.onbeforeunload = () => false;
          commit(`setNewWindowOpen`, { bool: true });
          dispatch(`UNSUBSCRIBE_ALL`);
        }
      };

      await tc( () => pusher.subscribe(`userUuid-${userUuid}`, {
        'App\\Events\\UserTabsEvent': userOpenNewTab,
      }), console.error);

      await tc(() => pusher.subscribe(`private-reload-${uuid}`, {
        'App\\Events\\ReloadEvent': ({ resources }: ReloadEventData) => {
          console.log('pusher - resources', resources);
          resources.forEach((r) => dispatch(`LOAD_${constantCase(r)}`));
        },
      }), console.error);

      // @deprecated
      await tc(() => pusher.subscribe(`office.${uuid}`, {
        'App\\Events\\ApptTreatmentOrderUpdateEvent': onAppointmentChanged,
      }), console.error);
    },
    TRIGGER_CLIENT_RELOAD(
      { state: { auth: { office: uuid }}},
      resources: string[],
    ) {
      pusher.trigger(`private-reload-${uuid}`, 'client-reload', { resources });
    },
    UNSUBSCRIBE_ALL() {
      pusher.unsubscribeAll();
    },
  },
  modules: { auth, scheduler, booking },
});
