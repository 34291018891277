
















































import Vue from 'vue';
import {trim} from 'lodash';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'AddNewNote',
  props: {
    notes: Array,
    treatmentId: {
      type: String,
      default: ''
    },
    appointmentId: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    hideHeaderText: {
      type: Boolean,
      default: false,
    },
    isAppointmentDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      adding: false,
      addTreatment: false,
      newNote: '',
      error: false,
      hint: '',
      editedNotes: [],
      currentUser: '',
      selectedTreatment: '',
    }
  },
  computed: {
    ...mapState({
      // @ts-ignore
      virtualKeyboardValues: (state) => state.virtualKeyboardValues,
      // @ts-ignore
      virtualKeyboardRelatedId: (state) => state.virtualKeyboardRelatedId,
      // @ts-ignore
      showVirtualKeyboard: (state) => state.showVirtualKeyboard,
      // @ts-ignore
      treatments: (state) => state.treatments,
    }),
    patientName() {
      const name = this.notes[0].appointment_treatment?.appointment?.patient.name;

      if (name && name.slice(0,3) === 'Dr.') {
        return name.slice(4);
      }

      return name;
    },
    headerRoomName() {
      const name = this.notes[0].appointment_treatment?.room?.name ?? '';
      return `${name} ${name ? ' | ' : ''}`;
    },
    treatmentFullName() {
      return this.notes[0].treatment.full_name;
    }
  },
  watch: {
    virtualKeyboardValues() {
      const virtualKeyboardRelatedIdExploded = this.virtualKeyboardRelatedId.split('.-.')

      if (this.virtualKeyboardRelatedId === "note-treatmentDialogAdd"){
        this.newNote = this.virtualKeyboardValues
      } else if( virtualKeyboardRelatedIdExploded[0] === 'note' ){
        const key = parseInt(virtualKeyboardRelatedIdExploded[2])
        this.editedNotes[key].message = this.virtualKeyboardValues
      }
    },
    newNote(){
      if (this.newNote !== this.virtualKeyboardValues){
        this.$store.dispatch('setKeyboardInput', { input: this.newNote })
      }
      if (this.newNote === '') {
        this.$emit('set-allowed-close', true);
      }
    }
  },
  created(){
    this.currentUser = this.$store.state.auth.user.uuid
  },
  methods: {
    async createNote(event) {
      event.target.blur();

      if (this.addTreatment && !this.selectedTreatment) {
        this.$eventBus.$emit('emit-error', { state: true, text: "Choose in which treatment you want to create a note!" });
        return;
      }

      const payload = {
        params: {
          message: this.newNote,
          treatment_id: this.addTreatment ? this.selectedTreatment : null,
          patient_id: this.patientId
        }
      };

      const newNoteBefore = this.newNote;
      this.newNote = '';
      this.adding = false;

      const success = await this.$store.dispatch('CREATE_NOTE', payload);
      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Added note.' });
        this.$emit('set-allowed-close', true);
        this.$emit('refresh-notes');

      } else {
        this.$eventBus.$emit('emit-error', { state: true, text: "Couldn't create a note, something went wrong!" });
        this.newNote = newNoteBefore;
        this.adding = true;
      }
    },
    handleFocusedInput(idOfRelated, message){
      if (this.virtualKeyboardRelatedId !== idOfRelated && this.showVirtualKeyboard){
        this.$store.dispatch('setVirtualKeyboardRelatedId', { idOfRelated })
        this.$store.dispatch('setKeyboardInput', { input: message })
      }
    },
    clearNote() {
      this.newNote = '';
      this.$emit('set-allowed-close', true);
    },
    toggleOffAddingNote() {
      this.newNote = '';
      this.adding = false;
      this.$emit('set-allowed-close', true);
    },
    newNoteFocus() {
      this.adding = true;

      this.$nextTick(() => {
        this.$refs[`new-note`].focus();
      });

      this.handleFocusedInput('note-treatmentDialogAdd', this.newNote);
    },
    checkIsSaved(i) {
      this.editedNotes[i].message = trim(this.editedNotes[i].message)

      if (this.editedNotes[i].message !== this.notes[i].message) {
        this.$nextTick(() => {
          this.$refs[`${this.patientName}_note_input_${i}`][0].focus();
        });

        this.$emit('set-allowed-close', false);
        this.$eventBus.$emit('emit-error', {state: true, text: 'Please, save the latest changes in the notes so as not to lose the update!'});
      }
    },
    checkIsSavedNewNote():boolean {
      const notesIsNull = ['', null, undefined].includes(this.newNote);
      if (!notesIsNull) {
        this.$nextTick(() => {
          this.$refs[`new-note`].focus();
        });

        this.$emit('set-allowed-close', false);
        this.$eventBus.$emit('emit-error', {state: true, text: 'Please, save or delete your new note!'});

        return false;
      } else {
        this.$emit('set-allowed-close', true);
      }

      return true;
    },
  }
})
