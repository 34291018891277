<template>
  <v-card
    class="p-2 text-sm flex"
    :class="{
      error,
      'text-center': small,
      'justify-center': small,
      'justify-between': !small && selected,
      'remove-border': noBorder
    }"
    :color="bgColor"
    :disabled="disabled"
    :max-width="maxWidth"
    :ripple="!unripple"
    :style="{ color: this.selected && this.small ? 'white' : 'black' }"
    @click="onCardClicked"
  >
    <div class="label">
      {{ text }}
      <div
          v-if="initials"
          class="text-xs font-bold text-center d-inline pa-1 rounded"
          :style="{
          backgroundColor: initialsBackgroundColor,
          color: $options.filters.foregroundColor(initialsBackgroundColor),
        }"
      >
        {{ initials }}
      </div>
      <span
          v-if="duration"
          class="pa-1 text-xs font-weight-light"
      >
        {{ this.durationFormat }}
      </span>
    </div>
    <div
      v-if="!small && selected"
      class="action-icon"
    >
      <v-btn disabled icon>
        <v-icon
          :size="18"
          :style="{ fill: $vuetify.theme.themes.light.green.darken1 }"
        >{{ icons.selected }}</v-icon>
      </v-btn>
    </div>
    <div
      v-if="error"
      class="error-indicator"
    >
      <span>No practitioners</span>
      <v-icon
        :size="18"
        :style="{ fill: $vuetify.theme.themes.light.red }"
      >{{ icons.error }}</v-icon>
    </div>
    <div
      v-if="remove && !error"
      class="action-icon"
    >
      <v-btn
        icon
        @mousedown.stop
        @touchstart.stop
        @click.stop="onRemoveClicked"
      >
        <v-icon
          :size="18"
          :style="{ fill: $vuetify.theme.themes.light.gray.base }"
        >{{ icons.remove }}</v-icon>
      </v-btn>
    </div>
    <div
      v-if="context && !error"
      class="action-icon"
    >
      <v-btn
        icon
        @mousedown.stop
        @touchstart.stop
        @click.stop="onContextClicked"
      >
        <v-icon
          :size="18"
          :style="{ fill: $vuetify.theme.themes.light.gray.base }"
        >{{ icons.context }}</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue';
import {
  mdiAlert,
  mdiCheckboxMarkedCircleOutline,
  mdiDelete,
  mdiDotsHorizontal
} from '@mdi/js';
import randomColor from "randomcolor";

export default Vue.extend({
  name: 'TextCard',
  props: {
    context: { type: Boolean, default: false },
    initials: { type: String, required: false },
    initialsColor: { type: String, required: false },
    duration: { type: Number, required: false },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: '' },
    remove: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    text: { type: String, required: true },
    unripple: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false }
  },
  data() {
    return {
      icons: {
        context: mdiDotsHorizontal,
        remove: mdiDelete,
        error: mdiAlert,
        selected: mdiCheckboxMarkedCircleOutline,
      }
    }
  },
  computed: {
    bgColor() {
      if (this.small && this.selected) {
        return 'primary';
      } else if (this.small) {
        return 'gray darken-1';
      }
      return 'gray lighten-1';
    },
    maxWidth() {
      return this.small ? 112 : 360;
    },
    initialsBackgroundColor() { return this.initialsColor || randomColor() },
    durationFormat() {
      const minutes = this.duration / 60
      const seconds = this.duration % 60

      return minutes > 0 ? (parseInt(minutes) + " min " + (seconds > 0 ? seconds + " sec" : '')) : (seconds + " sec")
    }
  },
  methods: {
    onCardClicked(e) {
      e.target.blur()
      this.$emit('click')
      if (this.error) this.$emit('click:card-error')
      else this.$emit('click:card-default')
    },
    onContextClicked(e) {
      e.target.blur()
      this.$emit('click:context')
    },
    onRemoveClicked(e) {
      e.target.blur()
      this.$emit('click:remove')
    },
  }
})
</script>

<style scoped>
.v-application .v-card.error {
  border: 2px solid var(--v-red-base) !important;
}

.action-icon {
  margin-top: -8px;
  margin-bottom: -8px;
}

.label {
  flex: 1;
}

.error-indicator {
  color: var(--v-red-base);
  font-weight: bold;
}

.error-indicator > * + * {
  @apply ml-2
}

.remove-border {
  box-shadow: unset !important;
}
</style>
