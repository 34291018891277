





























import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  name: 'ConfirmationDialog',
  computed: mapState(['confirmation']),
  methods: {
    ...mapActions(['SET_CONFIRMATION_WAS_CONFIRMED']),
    onYesClicked() { this.SET_CONFIRMATION_WAS_CONFIRMED(true) },
    onCancelClicked() { this.SET_CONFIRMATION_WAS_CONFIRMED(false) },
  }
})
