import {Module} from 'vuex';
import ApiService from '../../services/api.service';
import type {RootState} from '..';

export interface SchedulerState {
  templates: any[];
  slots: any[];
  isSlotsLoaded: boolean;
}

export const scheduler: Module<SchedulerState, RootState> = {
  namespaced: false,
  state: {
    templates: undefined,
    slots: undefined,
    isSlotsLoaded: undefined,
  },
  getters: {
    templates: (state) => state.templates,
    slots: (state) => state.slots,
    isSlotsLoaded: (state) => state.isSlotsLoaded,
  },
  mutations: {
    setTemplates: (state, { templates }: { templates: any[]}) => {
      state.templates = templates;
    },
    setSlots: (state, { slots }: { slots: any[]}) => {
      state.slots = slots;
      state.isSlotsLoaded = true;
    },
    resetSlots: (state) => {
      state.slots = [];
    },
  },
  actions: {
    async LOAD_LOCATION_TEMPLATES({commit}, payload: { locationUUID: string}) {
      const { locationUUID } = payload;
      try {
        const { data: { templates}} = await ApiService.customRequest({
          method: 'get',
          url: `scheduler/templates/${locationUUID}`,
        });
        commit('setTemplates', { templates });
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('LOAD_LOCATION_TEMPLATES ERROR - ', e);
      }

    },
    async SEARCH_SLOTS({commit}, payload) {
      try {
        const { data: { slots }} = await ApiService.customRequest({
          method: 'post',
          url: `scheduler/search`,
          data: payload,
        });
        commit('setSlots', { slots });
      } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('SEARCH_SLOTS ERROR - ', e);
      }
    },

  },
};
