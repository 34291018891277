
import Vue from "vue";
import Insurances from "@/components/static/Insurances.vue";

export default Vue.extend({
  name: "SelectAppointmentType",
  components: { Insurances },
  props: {
    selectedPatientType: {
      type: String,
      required: true,
    },
    nextPage: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    apptTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      patientTypes: [
        { label: "New Patient", value: "new" },
        { label: "Existing Patient", value: "existing" },
      ],
      appointmentTypes: [],
      patientType: "new",
    };
  },
  created() {
    this.appointmentTypes = this.apptTypes;
    this.patientType = this.selectedPatientType;
  },
  watch: {
    patientType: function () {
      this.$emit("set-patient-type", this.patientType);
    },
    apptTypes() {
      this.appointmentTypes = this.apptTypes;
    },
  },
  computed: {},
  methods: {
    nextScreen(value) {
      this.$emit("set-selected-type", value);
      this.$emit("next-step", this.nextPage);
    },
    clickOnInsurance(paymentType: string) {
      if (paymentType === "Insurance") {
        const insurances = document.getElementById("insurancesList");

        if (insurances) {
          insurances.scrollIntoView({ behavior: "smooth" });
        } else {
          console.error(`Element with id insurancesList not found.`);
        }
      }
    },
  },
});
