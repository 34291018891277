



























































































































































































































































import {boolean} from 'boolean';
import Vue from 'vue';
import draggable from 'vuedraggable';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

import PatientListItem from '@/components/basic/PatientListItem.vue';
import TextCard from '@/components/cards/TextCard.vue';
import TreatmentNotesCard from '@/components/cards/TreatmentNotesCard.vue';
import AppointmentsList from '@/components/modules/AppointmentsList.vue';
import equal from 'fast-deep-equal/es6';
import randomColor from 'randomcolor';
import {mdiClose} from '@mdi/js';
import AddNewNote from '@/components/cards/AddNewNote.vue';

declare interface Treatment {
  uuid: string
  name: string
}

declare interface BaseData {
  dragging: boolean
  isProcessing: boolean
  showDraggable: boolean
  closeIcon: any;
  treatmentsDoctors: object[]
  apptTreatmentList: []
  openAppointment: any;
  activeTab: string
  notes: []
  interactWithBoard: boolean
}

export default Vue.extend({
  name: 'AppointmentTreatmentSelector',
  components: {AddNewNote, TreatmentNotesCard, draggable, PatientListItem, TextCard, AppointmentsList },
  data(): BaseData {
    return {
      dragging: false,
      isProcessing: false,
      closeIcon: mdiClose,
      showDraggable: true,
      treatmentsDoctors: [],
      apptTreatmentList: [],
      openAppointment: undefined,
      activeTab: 'treatments',
      notes: [],
      interactWithBoard: false,
    };
  },
  computed: {
    ...mapGetters(['currentOpenFlags','currentOpenTractionFlags', 'currentOpenProfiles']),
    ...mapState({
      // @ts-ignore
      appointment: state => state.appointment,
      // @ts-ignore
      confirmation: state => state.confirmation,
      // @ts-ignore
      doctors: state => state.doctors,
      // @ts-ignore
      lastAppointment: state => state.lastAppointment,
      // @ts-ignore
      dashboard: state => state.auth.dashboard,
      treatments({ auth: { dashboard }, rooms, treatments }) {
        const { uuid } = dashboard?.rooms?.find(({ appointments: a }) =>
          a?.find(x => x?.uuid === this.appointment?.uuid)
        ) ?? {}
        if (!!this.appointment && !['processing','paused'].includes(this.appointment.status) ) {
          return treatments.filter(({is_enabled}) => is_enabled === 'yes')
        }
        return rooms.find(x => x?.uuid === uuid)?.treatments.filter(({is_enabled}) => is_enabled === 'yes') ?? []
      },
      appointmentHasCompletedTreatment() {
        const completedTreatments = this.appointment.treatments.filter((t) => t.status === 'completed');
        return completedTreatments?.length > 0;
      },
      room({ auth: { dashboard }, rooms }) {
        const { uuid } = dashboard?.rooms?.find(({ appointments: a }) =>
          a?.find(x => x?.uuid === this.appointment?.uuid)
        ) ?? {}
        return rooms.find(x => x?.uuid === uuid)
      },
      user: (state: any) => state.auth.user
    }),
    canApplyLastAppointment() {
      return !this.appointment?.treatments?.length && this.lastAppointment?.uuid
    },
    completedTreatments() {
      const completed = this.appointment.treatments.filter(t => t.ended_at);
      return this.$_.orderBy(completed, 'ended_at');
    },
    candidateToManual() {
      return this.appointment.status === 'pending'
    },
    todayAndNotCompleted() {
      const isToday = this.$dayjs(this.appointment.starts_at).isToday();
      const onDashboard = ['processing', 'paused'].includes(this.appointment.status);
      const notCompleted = !['completed'].includes(this.appointment.status);

      return isToday && (onDashboard || notCompleted)
    },
    canCheckOut() {
      return !['waiting', 'completed', 'cancelled', 'pending'].includes(this.appointment.status) && !this.appointment.ended_at
    },
    canReturnToArrived() {
      return ['completed'].includes(this.appointment.status) && this.appointment.ended_at
    },
    canReturnBackToUpcoming() {
      return this.appointment.status === 'waiting' && this.appointment.manual
    },
    mainBtnText() {
      return ['processing', 'paused'].includes(this.appointment.status)
        ? 'CHECK OUT'
        : 'START';
    },
  },
  watch: {
    appointment({ patient: { uuid = null } = {}} = {}) {
      console.log('triggered appointment', this.appointment.patient.name);
      this.copyTreatmentsForDisplay();

      if (this.openAppointment !== this.appointment.uuid) {
        const payload = {
          appointmentId: this.appointment.uuid
        }
        this.openAppointment = this.appointment.uuid;
        this.$store.dispatch('LOAD_SELECTED_APPOINTMENT', payload);
      }

      this.loadPatientAppointmentsNotes();
      this.setApptTreatmentList();
    },
    confirmation({ uuid, wasConfirmed }) {
      if (uuid===this.appointment.uuid && wasConfirmed !== null) {
        this.$store.dispatch('RESET_CONFIRMATION')
        if (wasConfirmed) this.handleCheckOutAppointment()
      }
    },
    doctors() {
      this.treatmentsDoctors = this.treatments.map(t => ({uuid: t.uuid, hasDoctors: false}));
      this.treatmentsDoctors.forEach(t => this.setTreatmentsDoctors(t));
    },
    treatments() {
      this.treatmentsDoctors = this.treatments.map(t => ({uuid: t.uuid, hasDoctors: false}));
      this.treatmentsDoctors.forEach(t => this.setTreatmentsDoctors(t));
    },
    apptTreatmentList: "sortTreatments",
    activeTab: "getActiveTabInfo",
  },
  async mounted() {

    this.interactWithBoard = await this.$store.dispatch('ableUserTo','interact with board');

    await this.LOAD_ROOMS();

    // treatmentsDoctors should be replaced with a computed property
    this.treatmentsDoctors = this.treatments.map((t: any) => ({
      hasDoctors: false,
      uuid: t.uuid
    }))
    this.treatmentsDoctors.forEach((t: any) => this.setTreatmentsDoctors(t))

    if (this.openAppointment !== this.appointment.uuid) {
      const payload = {
        appointmentId: this.appointment.uuid
      }
      this.openAppointment = this.appointment.uuid;
      this.$store.dispatch('LOAD_SELECTED_APPOINTMENT', payload);
    }
  },
  methods: {
    ...mapActions([
      'ADD_APPOINTMENT_TREATMENT',
      'ASK_FOR_CONFIRMATION',
      'DELETE_APPOINTMENT_TREATMENT',
      'DELETE_APPOINTMENT_ALL_TREATMENTS',
      'LOAD_DASHBOARD',
      'LOAD_ROOMS',
      'RESET_CONFIRMATION',
      'selectTreatment',
      'UPDATE_APPOINTMENT_STATUS',
      'HANDLE_COMPLETE_TREATMENT',
      'UPDATE_APPT_TREATMENT_ORDER',
      'LOAD_PATIENT_NOTES',
      'LOAD_PATIENT_APPOINTMENTS',
    ]),
    ...mapMutations([
      'toggleAppointment',
      'updateStopLastAppointmentsOnMount'
    ]),
    flagStyle(string) {
      const substr = 'MED FLAG';
      let style = { backgroundColor: '#FFDEDE', color: '#AB0000', fontWeight: '500'};
      if (!string.includes(substr)) {
        style = { backgroundColor: '#EBE7E7', color: '#000', fontWeight: '500'};
      }

      return style;
    },
    closeModal() {
      this.$emit('close-dialog');
    },
    setAllowedToClose(value: boolean) {
      this.$emit('set-allowed-close', value)
    },
    copyTreatmentsForDisplay(){
      this.apptTreatmentList = JSON.parse(JSON.stringify(this.appointment?.treatments))
    },
    async sortTreatments() {
      const { uuid: appointmentId } = this.appointment
      const draggedList = this.getOrder(this.apptTreatmentList);
      const oldOrderList = this.getOrder(this.appointment.treatments);

      if (!equal(draggedList, oldOrderList)){

        const success = await this.UPDATE_APPT_TREATMENT_ORDER({
          appointmentId,
          params: {
            treatmentsUuidAr: draggedList
          }
        })

        if(success){
          this.$eventBus.$emit('emit-success', { state: true, text: 'Order update was succeed!' });
          this.dashboard
              .rooms.find(({ appointments: a }) =>
              a?.find(x => x?.uuid === this.appointment?.uuid)
          )
              .appointments[0].treatments = this.apptTreatmentList
        } else {
          this.$eventBus.$emit('emit-error', { state: true, text: 'Order update was Failed!' });
        }
      }

    },
    async getActiveTabInfo() {

      if (this.activeTab === 'notes') {
        const data = await this.LOAD_PATIENT_NOTES()

        if (data) {
          // this.notes = data.patient.notes;
          const notes = this.$_.groupBy(data.patient.notes, 'treatment.uuid');
          this.notes = notes ?? [];
        }
      } else if (this.activeTab === 'appointments') {
        const data = await this.LOAD_PATIENT_APPOINTMENTS()
      }
    },
    async loadPatientAppointmentsNotes() {
      if (this.activeTab === 'notes') {
        const data = await this.LOAD_PATIENT_NOTES()

        if (data) {
          // this.notes = data.patient.notes;
          const notes = this.$_.groupBy(data.patient.notes, 'treatment.uuid');
          this.notes = notes ?? [];
        }
      }
    },
    async refreshNotes() {
      const data = await this.LOAD_PATIENT_NOTES()

      if (data) {
        this.notes = this.$_.groupBy(data.patient.notes, 'treatment.uuid');
      }
    },
    setApptTreatmentList(){

      if (!equal(this.apptTreatmentList, this.appointment.treatments)){
        this.apptTreatmentList = Object.assign([], this.appointment?.treatments);
      }
    },
    getOrder(treatments){
      const treatmentsUuidAr = [];

      if (treatments.length === 0) { return treatmentsUuidAr }

      treatments.map((t) => {
        treatmentsUuidAr.push(t.uuid)
      })

      return treatmentsUuidAr;
    },
    async applyLastAppointment() {
      this.isProcessing = true
      const payload = {
        appointmentId: this.appointment.uuid,
        params: {
          prevAppointmentUuid: this.lastAppointment?.uuid
        }
      }

      const success = await this.$store.dispatch('LOAD_LAST_APPOINTMENT_TREATMENTS', payload);

      if (success) {
        this.$eventBus.$emit('emit-success', { state: true, text: 'Last appointment treatments loaded' });
      } else {
        this.$eventBus.$emit('emit-error', {state: true, text: 'Couldn\'t load last appointment treatments'});
      }

      this.isProcessing = false
    },
    checkMove(e: any) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    async deleteTreatment(uuid: string) {
      const { uuid: appointmentId } = this.appointment
      this.apptTreatmentList.filter( (t) => t.uuid !== uuid )
      await this.DELETE_APPOINTMENT_TREATMENT({ appointmentId, params: {
        treatment: uuid
      }})
    },
    async handleCheckOutAppointment() {
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          status: statusBeforeCheckout,
          uuid: appointmentId
        }
      } = this
      const params = {
        status: {
          waiting: 'processing',
          paused: 'completed',
          processing: 'completed'
        }[statusBeforeCheckout],
        user,
        localInfo: {
          appointmentId
        }
      }
      await this.UPDATE_APPOINTMENT_STATUS({ appointmentId, params })
      this.toggleAppointment({ bool: false })
    },
    async handleClearAppointment() {
      const { treatments } = this.appointment
      if (!treatments?.length) return;
      const hasActiveTreatment = treatments.find((t) => ['paused', 'processing'].includes(t.status));
      let message = 'Are you sure to delete all treatments?';

      if (hasActiveTreatment) {
        console.log('hasActiveTreatment', hasActiveTreatment);
        message += ' You have active treatment!';
      }

      const result = confirm(message);
      console.log('result', result);

      if (result) {
        this.showDraggable = false;
        const { uuid: appointmentId } = this.appointment;
        this.apptTreatmentList.filter((t) => false);
        await this.DELETE_APPOINTMENT_ALL_TREATMENTS({ appointmentId });
        setTimeout(() => this.showDraggable = true, 100);
      }
    },
    handleSelectTreatment(treatment: Treatment) {
      this.selectTreatment({ treatment })
      this.$emit('open-treatment-step')
    },
    async onCardDefaultClicked({ uuid: treatment }) {

      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          uuid: appointmentId
        },
      } = this
      const room = this.room?.uuid ?? null

      const payload = { appointmentId, params: {
          doctor:
              this.appointment?.doctor?.uuid
              ?? this.doctors
                  .filter(({ is_enabled: x }) => boolean(x))
                  .find(d => d.treatments.find(t => t.uuid === treatment))
                  .uuid,
          duration: this.doctors
              .filter(({ is_enabled: x }) => boolean(x))
              .find(d => d.treatments.find(t => t.uuid === treatment))
              .treatments.find(t => t.uuid === treatment).duration * 60,
          room,
          treatment,
          user,
        }}
      await this.ADD_APPOINTMENT_TREATMENT(payload);
      this.setApptTreatmentList();
    },
    async onTreatmentRemoveClicked(uuid: string) {
      await this.deleteTreatment(uuid)
    },
    setTreatmentsDoctors(treatment) {
      let filteredDoctors = this.doctors.filter(dr => {
        return dr.treatments.find(t => t.uuid === treatment.uuid);
      });
      if (filteredDoctors.length > 0) {
        let updatedTreatmentsDoctors = [...this.treatmentsDoctors];
        updatedTreatmentsDoctors[updatedTreatmentsDoctors.findIndex(t => t.uuid === treatment.uuid)].hasDoctors = true;
        this.treatmentsDoctors = updatedTreatmentsDoctors;
      }
    },
    showConfirmation() {
      this.ASK_FOR_CONFIRMATION({
        message: 'This appointment will be removed from the board after checkout',
        uuid: this.appointment.uuid
      });
    },
    treatmentName(treatment: any): string {
      if (!treatment) return '';
      return treatment?.treatment ? treatment.treatment.name : 'Update to get correct Title';
    },
    doctorStyle(doctor){
      const backgroundColor = doctor?.color ?? randomColor()
      return {
        backgroundColor,
        color: this.$options.filters.foregroundColor(backgroundColor)
      }
    },
    async manualCheckIn() {
      console.log('MANUAL CHECK-IN ACTION')
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          uuid: appointmentId
        }
      } = this
      const params = {
        status: 'manual',
        user,
        localInfo: {
          appointmentId
        }
      }

      await this.UPDATE_APPOINTMENT_STATUS({ appointmentId, params })
    },
    async returnToUpcoming() {
      console.log('MANUAL APPOINTMENT RETURNING TO UPCOMING')
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          uuid: appointmentId
        }
      } = this
      const params = {
        status: 'upcoming',
        user,
        localInfo: {
          appointmentId
        }
      }
      await this.UPDATE_APPOINTMENT_STATUS({ appointmentId, params })
    },
    async checkInBack() {
      console.log('Appointment returning back from checked out column');
      const {
        $store: { state: { auth: { user: { uuid: user }}}},
        appointment: {
          uuid: appointmentId,
          status
        }
      } = this
      const params = {
        status: 'waiting',
        user,
        localInfo: {
          appointmentId,
          status: status
        }
      }
      await this.UPDATE_APPOINTMENT_STATUS({ appointmentId, params })
    },
  }
})
